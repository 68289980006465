
import {
  parseDataToFlat, parseDatetimeValues, parametersFormatter,
} from 'app/utils';
import { api } from './index';


function getCDRsExports({ query, credentials }) {
  const queryString = parametersFormatter(query);
  return api(`/v2/cdrs/exports?${queryString}`, { credentials, getMeta: true })
    .then(parseDatetimeValues)
    .then(({ data, ...others }) => Promise.resolve({ data: parseDataToFlat(data), ...others }));
}

function createCDRsExports(args) {
  return api('/v2/cdrs/exports', { ...args, getMeta: true })
    .then(parseDatetimeValues)
    .then(response => Promise.resolve({ data: parseDataToFlat([response]) }));
}


export default {
  get: getCDRsExports,
  create: createCDRsExports,
};
