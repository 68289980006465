import { makeReduxAssets } from 'resource-toolkit';
import * as CommonAPI from 'app/api';
import { v4 as uuidv4 } from 'uuid';

const namespace = 'PORTORDER-COSTS';

export const portorderCostsResource = makeReduxAssets({
  name: namespace,
  idKey: 'id',
  gateway: {
    fetchMany:
        // TODO
        // Once makeReduxAssets has a working fetchOne, we should refactor the python endpoint
        // to return one object instead of an array of objects, which is just a hack to work
        // with makeReduxAssets.  Then, we wouldn't need to insert a v4 uuid on each object
        // here, either.
        async () =>
            (await CommonAPI.getPortorderCosts())
                .map(costs =>
                    ({ ...costs, id: uuidv4() })),
  },
});

export const { initialState } = portorderCostsResource;

export const portorderCostsActions = portorderCostsResource.actions;

export default portorderCostsResource.reducer;
