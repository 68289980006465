import React, { Fragment } from 'react';
import Alert, { alertTypes } from 'app/components/alerts/alert';

export function formErrorMessage(message, serviceName) {
  return (
    message || `Unable to connect to ${serviceName || 'the'} service.`
  );
}

function RequestError({ serviceName, message, onClose }) {
  const errorMessage = (
    <Fragment>
      {formErrorMessage(message, serviceName)}
    </Fragment>
  );

  return (
    <Alert
      message={errorMessage}
      onClose={onClose}
      type={alertTypes.DANGER}
    />
  )
}

export default RequestError;