import React from 'react';
import PropTypes from 'prop-types';
import LoadingState from 'app/components/loading-state';
import RequestError from 'app/components/alerts/request-error';

const ResourceStatusv2 = ({
  children,
  response,
  retry,
  className,
  placeholder,
}) => {
  if (!response.data.length) {
    if (response.isLoading && response.isInitialState) {
      return placeholder || <LoadingState className={className} />;
    }

    const onRetry = (event) => {
      event.preventDefault();

      retry();
    };

    if (response.isError) {
      return (
        <div className={className}>
          <RequestError message={response.message} />
          <p>
           We couldn't load the data to display the content.
            Please contact support
            or <a href="#" onClick={onRetry}>click here</a> to retry
          </p>
        </div>
      );
    }
  }
  return children;
};

ResourceStatusv2.propTypes = {
  placeholder: PropTypes.element,
};

ResourceStatusv2.defaultProps = {
  placeholder: null,
};

export default ResourceStatusv2;
