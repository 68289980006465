/*
The user is loading the app via Django, and they authenticated via a username
and password.  Meanwhile, we're talking to the public API using an API key
and a tech prefix.

This calls exchange_credentials on the server to swap one for the other so we
can proceed to use the API.  This should be the only time we talk to the server
that supplied the web components instead of the API.
*/
import has from 'lodash/has';
import noop from 'lodash/noop';
import {
  setCredentials,
  requestedCredentials,
  failedCredentials,
} from 'actions/credentials-actions';

const waitForResponse = ({ subscribe, getState }) => {
  let unsubscribe = noop;

  return new Promise((resolve, reject) => {
    unsubscribe = subscribe(() => {
      const { credentials } = getState();

      if (has(credentials, 'techprefix')) {
        resolve();
      } else if (has(credentials, 'error')) {
        reject(credentials.error);
      }
    });
  }).finally(() => {
    unsubscribe();
  });
};

export function exchangeCredentials({ getState, dispatch, subscribe }) {
  const state = getState();
  if (has(state, 'credentials.requested')) {
    return waitForResponse({ subscribe, getState });
  }

  dispatch(requestedCredentials());
  return fetch('/accounts/exchange_credentials/',
    { credentials: 'same-origin' })
    .then(response => response.json())
    .then((json) => {
      dispatch(setCredentials(json));
    })
    .catch((err) => {
      dispatch(failedCredentials(err));
      throw err;
    });
}

// eslint-disable-next-line camelcase
export const exchange_credentials = exchangeCredentials;

export default exchangeCredentials;
