import * as API from 'app/api';

export const RATEPLAN_SUCCEEDED = 'RATEPLAN_SUCCEEDED';
export const RATEPLAN_REQUESTED = 'RATEPLAN_REQUESTED';
export const RATEPLAN_FAILED = 'RATEPLAN_FAILED';
export const RATEPLAN_RANGE_UPDATED = 'RATEPLAN_RANGE_UPDATED';

export function rateplanSucceeded(payload) {
  return {
    type: RATEPLAN_SUCCEEDED,
    payload
  }
}

export function rateplanRequested() {
  return {
    type: RATEPLAN_REQUESTED,
  }
}

export function rateplanFailed(error) {
  return {
    type: RATEPLAN_FAILED,
    error
  }
}

export function setRateRange(range) {
  return {
    type: RATEPLAN_RANGE_UPDATED,
    range
  }
}

export function fetchRateplan(query) {
  return dispatch => {
    dispatch(rateplanRequested());

    return API.rateplan(query)
      .then(payload => payload)
      .then(body => dispatch(rateplanSucceeded(body)))
      .catch(error => dispatch(rateplanFailed(error)))
  }
}
