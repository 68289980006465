import { makeReduxAssets } from 'resource-toolkit';
import UserGroups from 'app/api/user-groups';

export const userGroupsResource = makeReduxAssets({
  name: 'USER_GROUPS',
  idKey: 'id',
  gateway: {
    fetchMany: (_, __) => UserGroups.get(),
  },
});

export const { initialState } = userGroupsResource;
export const userGroupsActions = userGroupsResource.actions;
export default userGroupsResource.reducer;
