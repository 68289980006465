import React from 'react';
import LoadingIcon from 'app/components/loading-icon';
import classNames from 'classnames'
import 'styles/_loading-state.scss';

const LoadingState = ({ className }) => (
  <div className={classNames('loading-state', className)}>
    <LoadingIcon />
    Loading data...
  </div>
);

export default LoadingState;
