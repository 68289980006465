import { fetchWithCsrfToken } from 'app/api/fetch';
import { parseResponse } from 'app/utils';

function getCountryStates(country, fetchParams = {}) {
  return fetchWithCsrfToken(
    `/api/countries/${country}/states`,
    { method: 'GET', credentials: 'include', ...fetchParams },
  ).then(parseResponse);
}

export default {
  get: getCountryStates,
};
