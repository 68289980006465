import React from 'react';
import TextArea from 'react-textarea-autosize';

export default class FRTextArea extends React.Component {
  static get keys() {
    return {
      ESC: 27,
      ENTER: 13
    }
  }

  constructor(props) {
    super(props);
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  onKeyUp(e) {
    if (e.keyCode === FRTextArea.keys.ENTER && !e.shiftKey) {
      e.stopPropagation();
      e.preventDefault();
      this.blur();
      this.props.submit();
    }

    if (e.keyCode === FRTextArea.keys.ESC) {
      this.blur();
      if (this.props.onBlur) {
        this.props.onBlur();
      }
    }

    this.props.onInput(e);
  }

  focus() {
    if (this.textArea) {
      this.textArea.focus();
    }
  }

  blur() {
    if (this.textArea) {
      this.textArea.blur();
    }
  }
  
  render() {
    const { onChange, onInput, inputRef, submit, onBlur, ...props } = this.props;

    return (
      <TextArea
        { ...props }
        inputRef={ref => this.textArea = ref}
        onInput={onInput}
        onKeyDown={this.onKeyUp}
      />
    )
  }
}
