import compose from '@ramda/compose';
import { fetchWithCsrfToken } from 'app/api/fetch';
import {
  parseResponse,
  parseBody,
} from 'app/utils';

const prepareBody = compose(
  parseBody,
);

function fetchDynamicRoutesByRoute(routeId) {
  return fetchWithCsrfToken(
    `/api/customer/routes/${routeId}/dynamic_routes`,
    { method: 'GET', credentials: 'include' },
  ).then(parseResponse);
}

function postDynamicRoutesByRoute(routeId, data) {
  return fetchWithCsrfToken(
    `/api/customer/routes/${routeId}/dynamic_routes`,
    { method: 'POST', credentials: 'include', body: prepareBody(data) },
  ).then(parseResponse).then(response => response.data);
}

function patchDynamicRoutesByRoute(routeId, dynamicRouteId, data) {
  return fetchWithCsrfToken(
    `/api/customer/routes/${routeId}/dynamic_routes/${dynamicRouteId}`,
    { method: 'PATCH', credentials: 'include', body: prepareBody(data) },
  ).then(parseResponse).then(response => response.data);
}

function deleteDynamicRouteByRoute(routeId, drouteId) {
  return fetchWithCsrfToken(
    `/api/customer/routes/${routeId}/dynamic_routes/${drouteId}`,
    { method: 'DELETE', credentials: 'include' },
  ).then(parseResponse);
}

export default {
  getByRoute: fetchDynamicRoutesByRoute,
  postByRoute: postDynamicRoutesByRoute,
  patchByRoute: patchDynamicRoutesByRoute,
  destroyByRoute: deleteDynamicRouteByRoute,
};
