import { fetchWithCsrfToken } from 'app/api/fetch';
import { parseResponse } from 'app/utils';

function fetchCountries() {
  return fetchWithCsrfToken(
    '/api/countries',
    { method: 'GET', credentials: 'include' },
  ).then(parseResponse);
}

export default {
  get: fetchCountries,
};
