import resourceReducerFactory, { resourceState, pagination } from 'reducers/resource';
import { types } from 'actions/outbound-allowed-ips';


export const initialState = {
  ...resourceState,
  isCreatingOutboundAllowedIp: false,
  isLoadingOutboundAllowedIps: false,
  pagination: {
    ...pagination,
    perPage: 25,
    numberOfPages: 1,
  },
};

export default resourceReducerFactory(initialState, types);
