import * as API from 'app/api';
import * as types from 'app/redux/types';

import resourceActionsFactory, {
  REQUESTED,
  FAILED,
  SUCEEDED,
} from 'actions/resource';

export const { dispatchers: actionDay, actionMap: typesDay } = resourceActionsFactory({
  [REQUESTED]: 'MESSAGE_STATUS/REQUESTED',
  [FAILED]: 'MESSAGE_STATUS/FAILED',
  [SUCEEDED]: 'MESSAGE_STATUS/SUCCEDED',
});

const requestedDay = actionDay[REQUESTED];
const suceededDay = actionDay[SUCEEDED];
const failedDay = actionDay[FAILED];

export function addDaySummaries(summaries) {
  return {
    type: types.ADD_DAY_SUMMARIES,
    summaries,
  };
}

export function daySummariesFailed() {
  return {
    type: types.DAY_SUMMARIES_FAILED,
  };
}

export function daySummariesRequest() {
  return {
    type: types.DAY_SUMMARIES_REQUEST,
  };
}


export function daySummariesLifetime() {
  return (dispatch, getState) => {
    const { credentials } = getState();
    dispatch(daySummariesRequest());

    return API.daySummariesLifetime({ credentials })
      .then(data => dispatch(addDaySummaries(data)))
      .catch(() => dispatch(daySummariesFailed()));
  };
}

export function loadMdrs(day, offset) {
  return (dispatch, getState) => {
    const { credentials } = getState();
    dispatch(requestedDay());

    return API.mdrs_by_day(day, offset, { credentials })
      .then(response => dispatch(suceededDay({ data: response })))
      .catch(error => dispatch(failedDay(error)));
  };
}
