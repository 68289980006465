import React from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import Alert, { alertTypes } from "./alert";
import "styles/alert/_toast.scss";
import "styles/transitions/_toast.scss";

const Toast = ({ visible, message, type, timeout, onClose }) => {
  const [isReallyVisible, setReallyVisible] = React.useState(visible);
  const previousVisible = React.useState(visible);

  const forceClose = () => {
    setReallyVisible(false);
    onClose();
  };

  React.useEffect(() => {
    if (previousVisible !== visible) {
      setReallyVisible(visible);
    }
  }, [previousVisible, visible]);

  React.useEffect(() => {
    if (!isReallyVisible) {
      return () => {};
    }

    const visibilityTimer = setTimeout(() => {
      setReallyVisible(false);
      onClose();
    }, timeout);
    return () => clearTimeout(visibilityTimer);
  }, [isReallyVisible, onClose, timeout]);

  return (
    <div className="react-toast" role="alert">
      <CSSTransition
        in={isReallyVisible}
        timeout={300}
        classNames="toast"
        unmountOnExit
      >
        <Alert message={message} type={type} onClose={forceClose} />
      </CSSTransition>
    </div>
  );
};

Toast.propTypes = {
  visible: PropTypes.bool.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  timeout: PropTypes.number,
};

Toast.defaultProps = {
  timeout: 5000,
  message: "",
  type: alertTypes.INFO,
};

export default Toast;
