import CNAMPresetAPI from 'app/api/cnam-preset';
import resourceActionsFactory, {
  REQUESTED,
  FAILED,
  SUCEEDED as SUCCEEDED,
  CLEAR_MESSAGE,
  methods,
} from 'actions/resource';
import {
  parseDatetimeValues,
} from 'app/utils';

export const { dispatchers: action, actionMap: types } = resourceActionsFactory({
  [REQUESTED]: 'CNAM_PRESET/REQUESTED',
  [FAILED]: 'CNAM_PRESET/FAILED',
  [SUCCEEDED]: 'CNAM_PRESET/SUCCEEDED',
  [CLEAR_MESSAGE]: 'CNAM_PRESET/CLEAR_MESSAGE',
});

const requested = action[REQUESTED];
const succeeded = action[SUCCEEDED];
const failed = action[FAILED];

export const clearCnamPreset = action[CLEAR_MESSAGE];

export function fetchCnamPresets(querySet) {
  return (dispatch) => {
    dispatch(requested());
    return CNAMPresetAPI.get(querySet)
      .then(parseDatetimeValues)
      .then(body => dispatch(succeeded(body)))
      .catch(error => dispatch(failed(error)));
  };
}

export function editCnamPreset(id, data) {
  return (dispatch) => {
    dispatch(requested(id, { method: methods.UPDATE }));
    return CNAMPresetAPI.put(id, data).then(response => dispatch(succeeded(
      response,
      id,
      { method: methods.UPDATED, updates: data },
    ))).catch((error) => {
      dispatch(failed(error, id, { method: methods.FAIL }));
    });
  };
}
