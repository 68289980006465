/*
If you're not sure what this is for, read the introduction at the top of
`reducers.js`, as well as the Redux tutorial.

An action is an object with a `type` field of type string, and optionally some
other data.  When the Store receives an action, it calls the top-level reducer
in order to generate the next state.

EXAMPLE: { type: 'SET_HELLO_MESSAGE', value: 'hello world' }

Note that an action does not do anything by itself, but rather it is an
instruction to the reducer which updates the store.

This file is a bunch of boilerplate for building actions.  We specify two things
 – one, a set of `const` values naming each action, and two, a set of functions
as thin wrappers to create those actions.  The reason we do this is to guard
against a variety of common errors, as well as to save time later.

We create all of the `const` values primarily to catch typographical errors.
You could use strings both here and in the reducers, but if they become
inconsistent then you will find your actions failing for no clear reason.
By using consts, these typos will cause a runtime error.  Trust me, it's worth
it.

EXAMPLE: export const SET_HELLO_MESSAGE = 'SET_HELLO_MESSAGE';

Beneath the consts, there are functions to construct the Action objects.
These should primarily just take data and place them in an object with
an appropriate `type` field.  They should not cause side-effects: they are
creating an object representing the action to be taken, not taking any actions
themselves.  They might gather ambient data from the environment such as the
current time.

EXAMPLE:  function setHelloMessage(msg) {
            return {
              type: SET_HELLO_MESSAGE,
              value: msg,
              action_created: Time.now(),
            }
          }

You might create several different functions which all create the same kind
of action, or you might create a function which creates several kinds of action.
Be careful that you're doing so for the right reasons!
*/


// Actions
export const SET_CREDENTIALS = 'SET_CREDENTIALS';
export const FAILED_CREDENTIALS = 'FAILED_CREDENTIALS';
export const REQUESTED_CREDENTIALS = 'REQUESTED_CREDENTIALS';


export const CHANGE_VIEW_SCOPE = 'CHANGE_VIEW_SCOPE';

// Action creators
export function setCredentials(credentials) {
  return {
    type: SET_CREDENTIALS,
    credentials,
  };
}

export function failedCredentials(error) {
  return {
    type: FAILED_CREDENTIALS,
    error,
  };
}

export function requestedCredentials() {
  return {
    type: REQUESTED_CREDENTIALS,
    timestamp: Date.now(),
  };
}
