import React, { Component } from 'react'
import ErrorInfo from './error-info';

class ErrorBoundary extends Component {

    constructor(props) {
        super(props)

        this.state = {
            hasError: false
        }
    }

    static getDerivedStateFromError(error) {
        return {
            hasError: true
        }
    }

    componentDidCatch(error, info) {
        console.log(error);
        console.log(info);
        }

    render() {
        if (this.state.hasError) {
            return (
                <ErrorInfo />
            )
        }
        return this.props.children;
    }
}

export default ErrorBoundary;

