import {
  parseDatetimeValues,
  parseResponse,
  addQueryString,
} from 'app/utils';
import { fetchWithCsrfToken } from 'app/api/fetch';

const fields = ['page', 'perPage', 'status', 'startDate', 'endDate', 'modifiedBy'];

function didHistoryRequest(did, query = {}) {
  const url = `/${window.SYS_LOGIN_PATH}/api/dids/${did}/history`;

  return fetchWithCsrfToken(addQueryString(url, fields, query), {
    method: 'GET', credentials: 'include',
  }).then(parseResponse)
    .then(parseDatetimeValues);
}

export default {
  get: didHistoryRequest,
};
