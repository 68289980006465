import {
  parseResponse, parseQuery, filterParams, parseBody,
} from 'app/utils';
import { fetchWithCsrfToken } from 'app/api/fetch';

function outboundAllowedIps(query) {
  const params = filterParams(query, ['ipAddress', 'perPage', 'page']);

  return fetchWithCsrfToken(
    `/api/outbound_allowed_ips?${parseQuery(params)}`,
    { method: 'GET', credentials: 'include' },
  ).then(parseResponse);
}

function createOutboundAllowedIp(data) {
  return fetchWithCsrfToken('/api/outbound_allowed_ips/', { method: 'POST', credentials: 'include', body: parseBody(data) })
    .then(parseResponse);
}

function editOutboundAllowedIp(id, data) {
  return fetchWithCsrfToken(
    `/api/outbound_allowed_ips/${id}`,
    { method: 'PUT', credentials: 'include', body: parseBody(data) },
  ).then(parseResponse);
}

function deleteOutboundAllowedIp(id) {
  return fetchWithCsrfToken(
    `/api/outbound_allowed_ips/${id}`,
    { method: 'DELETE', credentials: 'include' },
  ).then(parseResponse);
}

export default {
  get: outboundAllowedIps,
  post: createOutboundAllowedIp,
  put: editOutboundAllowedIp,
  destroy: deleteOutboundAllowedIp,
};
