import React from 'react';
import PropTypes from 'prop-types';
import PaginationStepper from 'app/components/pagination-stepper';
import numberOrNullProp from 'app/types/number-or-null-prop';

class Pagination extends React.Component {
  static propTypes = {
    page: PropTypes.number.isRequired,
    changePage: PropTypes.func.isRequired,
    // eslint-disable-next-line consistent-return
    numberOfPages(props, propName) {
      if (props.displayPageCount) {
        return numberOrNullProp(props, propName);
      }
    },
    disabled: PropTypes.bool.isRequired,
    displayPageCount: PropTypes.bool.isRequired,
    rightAligned: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    numberOfPages: null,
  };

  nextPage = () => {
    const page = this.props.page + 1;
    this.props.changePage({
      ...this.props,
      page,
    });
  };

  previousPage = () => {
    const page = this.props.page - 1;
    this.props.changePage({
      ...this.props,
      page,
    });
  };

  render() {
    return (
      <PaginationStepper
        prevPageFunc={this.previousPage}
        currentPage={this.props.page}
        totalPages={this.props.numberOfPages}
        nextPageFunc={this.nextPage}
        isLoading={this.props.disabled}
        displayPageCount={this.props.displayPageCount}
        rightAligned={this.props.rightAligned}
      />
    );
  }
}

export default Pagination;
