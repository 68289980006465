import React from 'react';
import { render } from 'react-dom';
import { parseProps } from 'app/utils';

const RenderRootComponent = (Component, query) => {
  const elements = document.querySelectorAll(`[root-react="${query}"]`);

  if (!elements.length) return;

  elements.forEach(element => (
    render(
      <Component {...parseProps(element)} />,
      element,
    )
  ));
};

export default RenderRootComponent;
