import {
  parseResponse,
  parseDatetimeValues,
} from 'app/utils';
import { fetchWithCsrfToken } from 'app/api/fetch';


export function getCustomerHistory(customerId) {
  return fetchWithCsrfToken(`/${window.SYS_LOGIN_PATH}/api/customers/${customerId}/history`, {
    method: 'GET', credentials: 'include',
  }).then(parseResponse)
    .then(parseDatetimeValues);
}

export default {
  get: getCustomerHistory,
};
