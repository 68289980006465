import * as balanceActions from 'actions/balance';

export const initialState = {
  isInitialState: true,
  isLoading: false,
  requestError: null,
  balance: 0,
  warningThreshold: 0,
  creditLimit: 0,
  availableFunds: 0,
  isPostpay: false,
};

const balance = (state = initialState, action) => {
  switch (action.type) {
    case balanceActions.BALANCE_SUCCEEDED:
      const { balance, warningThreshold, creditLimit, isPostpay, availableFunds } = action;
      return {
        ...state,
        isInitialState: false,
        availableFunds: Number(availableFunds),
        balance: Number(balance),
        warningThreshold: Number(warningThreshold),
        creditLimit: Number(creditLimit),
        isPostpay,
        isLoading: false,
        requestError: null,
      };
    case balanceActions.BALANCE_REQUESTED:
      return { ...state, isLoading: action.isLoading, requestError: null };
    case balanceActions.BALANCE_FAILED:
      return { ...state, isLoading: false, requestError: action.error };
    default:
      return state;
  }
};

export default balance;
