import { fetchWithCsrfToken } from 'app/api/fetch';
import {
  parseResponse,
  parseQuery,
  parseBody,
} from 'app/utils';

function getPortOrders(querySet = {}) {
  return fetchWithCsrfToken(`/system/api/port_orders?${parseQuery(querySet)}`, {
    method: 'GET',
  }).then(parseResponse);
}

function patchPortOrder(id, data = {}) {
  return fetchWithCsrfToken(`/system/api/port_orders/${id}`, {
    method: 'PATCH',
    body: parseBody(data),
  }).then(parseResponse);
}

export default {
  get: getPortOrders,
  patch: patchPortOrder,
};
