import CustomerNotes from 'app/api/customer-notes';
import resourceActionsFactory, {
  REQUESTED,
  FAILED,
  SUCEEDED,
  CLEAR_MESSAGE,
  methods,
} from 'actions/resource';

export const { dispatchers: action, actionMap: types } = resourceActionsFactory({
  [REQUESTED]: 'CUSTOMER_NOTES/REQUESTED',
  [FAILED]: 'CUSTOMER_NOTES/FAILD',
  [SUCEEDED]: 'CUSTOMER_NOTES/SUCCEDED',
  [CLEAR_MESSAGE]: 'CUSTOMER_NOTES/CLEAR_MESSAGE',
});

const requested = action[REQUESTED];
const suceeded = action[SUCEEDED];
const failed = action[FAILED];
export const clearMessage = action[CLEAR_MESSAGE];

export function get(customerId) {
  return (dispatch) => {
    dispatch(requested());

    return CustomerNotes.get(customerId)
      .then(response => dispatch(suceeded(response)))
      .catch(error => dispatch(failed(error)));
  };
}

export function edit(customerId, noteId, data) {
  return (dispatch) => {
    dispatch(requested(noteId, { method: methods.UPDATE }));

    return CustomerNotes.edit(customerId, noteId, data)
      .then(response => (
        dispatch(suceeded(
          response,
          noteId,
          {
            method: methods.UPDATED,
            updates: data,
          },
        ))
      ))
      .catch((error) => {
        dispatch(failed(error, noteId));

        throw error;
      });
  };
}

export function create(customerId, data) {
  return (dispatch) => {
    dispatch(requested());

    return CustomerNotes.create(customerId, data)
      .then(response => dispatch(suceeded(response, null, { method: methods.CREATED })))
      .catch(error => dispatch(failed(error)));
  };
}
