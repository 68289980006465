import React from 'react';
import PropTypes from 'prop-types';

function renderOptions(options) {
  return options.map(option => (
    <option key={option.value} value={option.value}>{ option.label }</option>
  ));
}

const LabeledSelect = props => (
  <div className={props.className || 'form-inline'}>
    <div className="form-group">
      <label htmlFor="dropdown">{ props.label }</label>
      <select
        id="dropdown"
        onChange={props.onChange}
        className="form-control"
        value={props.value}
        disabled={props.disabled}
      >
        {renderOptions(props.options)}
      </select>
    </div>
  </div>
);

LabeledSelect.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  })).isRequired,
  disabled: PropTypes.bool,
};

LabeledSelect.defaultProps = {
  className: '',
  value: '',
  disabled: false,
};

export default LabeledSelect;
