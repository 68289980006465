import Countries from 'app/api/countries';
import resourceActionsFactory, {
  REQUESTED,
  FAILED,
  SUCEEDED as SUCCEEDED,
} from 'actions/resource';

export const { dispatchers: action, actionMap: types } = resourceActionsFactory({
  [REQUESTED]: 'COUNTRIES/REQUESTED',
  [FAILED]: 'COUNTRIES/FAILED',
  [SUCCEEDED]: 'COUNTRIES/SUCCEEDED',
});

const requested = action[REQUESTED];
const succeeded = action[SUCCEEDED];
const failed = action[FAILED];

export function fetchCountries() {
  return (dispatch) => {
    dispatch(requested());

    return Countries.get()
      .then(response => dispatch(succeeded(response)))
      .catch(error => dispatch(failed(error)));
  };
}
