import React from 'react';
import PropTypes from 'prop-types';
import isNumber from 'lodash/isNumber';
import { between } from 'app/utils';

import 'styles/_pagination-stepper.scss';

export default class Pagination extends React.PureComponent {
  static propTypes = {
    boundary: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.shape({
        hasPrevious: PropTypes.bool.isRequired,
        hasNext: PropTypes.bool.isRequired,
      }),
    ]).isRequired,
    onPageChange: PropTypes.func.isRequired,
    current: PropTypes.number.isRequired,
    showCurrent: PropTypes.bool,
    showRemaining: PropTypes.bool,
    showSpinnerAtRight: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
  }

  static defaultProps = {
    showCurrent: true,
    showRemaining: true,
    showSpinnerAtRight: false,
    className: '',
    disabled: false,
  }

  static PREVIOUS = '@Pagination/PREVIOUS'

  static NEXT = '@Pagination/NEXT'

  constructor(props) {
    super(props);

    this.isNumericBoundary = isNumber(props.boundary);
  }

  componentDidUpdate() {
    const wasNumbered = this.isNumericBoundary;
    const isNowNumbered = isNumber(this.props.boundary);
    if (wasNumbered !== isNowNumbered) {
      console.warn(
        'Warning:',
        'Pagination being changed between numbered and numberless',
        'Decide consistently if `boundary` prop is a number or other object.',
      );
      this.isNumericBoundary = isNowNumbered;
    }

    if (this.isNumbered && ((this.props.boundary - this.props.current) < 0)) {
      this.setCurrentPageNumber(this.props.boundary);
    }
  }

  getCurrentPageNumber = () => this.props.current

  setCurrentPageNumber = (page) => {
    const pageInBounds = between(page, 1, this.props.boundary);
    this.props.onPageChange(pageInBounds);
  }

  onClickToPrevious = () => (this.isNumericBoundary
    ? this.setCurrentPageNumber(this.getCurrentPageNumber() - 1)
    : this.props.onPageChange(Pagination.PREVIOUS)
  )

  onClickToNext = () => (this.isNumericBoundary
    ? this.setCurrentPageNumber(this.getCurrentPageNumber() + 1)
    : this.props.onPageChange(Pagination.NEXT)
  )

  isFirstPage = () => (this.isNumericBoundary
    ? (this.getCurrentPageNumber() <= 1)
    : (!this.props.boundary.hasPrevious)
  )

  isLastPage = () => (this.isNumericBoundary
    ? (this.getCurrentPageNumber() >= this.props.boundary)
    : (!this.props.boundary.hasNext)
  )

  render() {
    return (
      <div className={`pagination pagination-thin pagination-light ${this.props.className}`}>
        <button
          type="button"
          onClick={this.onClickToPrevious}
          className="btn btn-small"
          disabled={this.props.disabled || this.isFirstPage()}
        >
          Prev
        </button>
        {isNumber(this.props.current) && this.props.showCurrent && (
          <span className="pagination-text">
            Page {this.getCurrentPageNumber()}
            {isNumber(this.props.boundary) && this.props.showRemaining && (
              ` of ${this.props.boundary}`
            )}
          </span>
        )}
        <button
          type="button"
          onClick={this.onClickToNext}
          className="btn btn-small"
          disabled={this.props.disabled || this.isLastPage()}
        >
          Next
        </button>
        {this.props.showSpinnerAtRight && (
          <span className="loading-icon">
            <i className="fa fa-spin fa-spinner" />
          </span>
        )}
      </div>
    );
  }
}
