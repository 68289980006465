import { fetchWithCsrfToken } from 'app/api/fetch';
import {
  parseResponse, prepareBody,
} from 'app/utils';

function fetchLocations(drouteId) {
  return fetchWithCsrfToken(
    `/api/customer/dynamic_routes/${drouteId}/location_rules`,
    { method: 'GET', credentials: 'include' },
  ).then(parseResponse);
}

function createLocation(drouteId, data) {
  return fetchWithCsrfToken(
    `/api/customer/dynamic_routes/${drouteId}/location_rules`,
    { method: 'POST', credentials: 'include', body: JSON.stringify(data) },
  ).then(parseResponse).then(response => response.data);
}

function deleteLocation(drouteId, locationId) {
  return fetchWithCsrfToken(
    `/api/customer/dynamic_routes/${drouteId}/location_rules/${locationId}`,
    { method: 'DELETE', credentials: 'include' },
  ).then(parseResponse).then(response => response.data);
}

export default {
  get: fetchLocations,
  post: createLocation,
  delete: deleteLocation,
};
