import React, {Fragment} from "react";
import PropTypes from "prop-types";
import onClickoutside from "react-onclickoutside";
import {CSSTransition} from "react-transition-group";
import classnames from "classnames";
import {FocusOn} from 'react-focus-on';

import "./modal.scss";

const ModalDialog = onClickoutside(
  ({ title, titleClassName, children, onClose, showCloseButton = true }) => (
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          {showCloseButton && (
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={onClose}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          )}
          <h4 className={titleClassName || "modal-title"}>{title}</h4>
        </div>
        <div className="modal-body">{children}</div>
      </div>
    </div>
  )
);

ModalDialog.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
};

export const Modal = ({
  isShow,
  title,
  titleClassName,
  children,
  onClose,
  showCloseButton = true,
  className
}) => (
  <Fragment>
    <CSSTransition
      in={isShow}
      classNames={{
        enterActive: "in",
        enterDone: "in",
      }}
      timeout={300}
      unmountOnExit
    >
      <div className="modal-backdrop fade" />
    </CSSTransition>
    {isShow && (
      <FocusOn>
        <div
          className={classnames("modal modal-flex", className)}
          tabIndex="-1"
          role="dialog"
          style={isShow ? { display: "flex" } : {}}
        >
          <ModalDialog
            title={title}
            titleClassName={titleClassName}
            onClose={onClose}
            showCloseButton={showCloseButton}
            handleClickOutside={onClose}
          >
            {children}
          </ModalDialog>
        </div>
      </FocusOn>
    )}
  </Fragment>
);

Modal.propTypes = {
  isShow: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Modal.defaultProps = {
  className: "",
};

export default Modal;
