import React, { Fragment } from 'react';
import LoadingState from 'app/components/loading-state';
import RequestError from 'app/components/alerts/request-error';

function ResourceStatus(props) {
  const { children, isLoading, response, retry, className } = props;

  const onRetry = (event) => {
    event.preventDefault();

    retry();
  };

  if (response && response.isError) {
    return (
      <div className={className}>
        <RequestError message={response.message} />
        <p>
         We couldn't load the data to display the content.
          Please contact support
          or <a href="#" onClick={onRetry}>click here</a> to retry
        </p>
      </div>
    )
  }

  if (isLoading) {
    return (<LoadingState className={className} />);
  }
  return children;
}

export default ResourceStatus;
