import {
  parseDatetimeValues,
  parseResponse,
  parseQuery,
  parseBody,
} from 'app/utils';
import { fetchWithCsrfToken } from 'app/api/fetch';

export function getDIDWithoutDatetimes(didValue, querySet = {}, fetchOptions = {}) {
  const url = `/${window.SYS_LOGIN_PATH}/api/dids/${didValue}?${parseQuery(querySet)}`;
  return fetchWithCsrfToken(url, {
    method: 'GET',
    credentials: 'include',
    ...fetchOptions,
  }).then(parseResponse);
}

export function getDID(...args) {
  return getDIDWithoutDatetimes(...args).then(parseDatetimeValues);
}

export function getDIDs(...args) {
  return getDID('', ...args);
}

function patchDIDs(didValue, data) {
  return fetchWithCsrfToken(`/${window.SYS_LOGIN_PATH}/api/dids/${didValue}`, {
    method: 'PATCH', credentials: 'include', body: parseBody(data),
  }).then(parseResponse)
    .then(parseDatetimeValues);
}


export default {
  getOne: getDID,
  getAll: getDIDs,
  patch: patchDIDs,
};
