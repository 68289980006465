import React from 'react';
import PropTypes from 'prop-types';
import * as Fields from 'components/segment/fields';
import VerticalSeparator from 'components/segment/vertical-separator';

class Form extends React.Component {
  static propTypes = {
    fields: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string.isRequired,
    })),
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    formState: PropTypes.string.isRequired,
    submitButton: PropTypes.shape({
      text: PropTypes.string,
    }),
  }

  state = {
    optionals: {},
    list: {},
  };


  static defaultProps = {
    fields: [],
    submitButton: {
      text: 'Send',
    },
  }

  onChangeList = isList => ({ target }) => {
    let valueInput = target.value;
    const fieldArray = target.name.match(/(.*?)\[(.*?)\]/i);
    if (fieldArray && isList) {
      const index = this.state.list[target.id].findIndex(field => field.id === parseInt(fieldArray[2], 10));
      if (index > -1) {
        this.state.list[target.id][index].value = valueInput;
      } else {
        this.state.list[target.id].push({ id: parseInt(fieldArray[2], 10), value: valueInput });
      }
      valueInput = this.state.list[target.id];

      this.onChangeListCallBack(target.id, valueInput);
    } else {
      this.props.onChange({
        target,
      });
    }
  }

  onChangeListCallBack(name, values) {
    this.props.onChange({
      target: {
        name,
        id: name,
        value: values.map(input => input.value).filter(value => !!value.length),
      },
    });
  }

  getGroup = (group) => {
    if (group.optional) {
      const groups = this.state.optionals[group.name] || [];
      return (
        <React.Fragment key={group.name}>
          {groups.map(groupProps => (
            <React.Fragment key={groupProps.id}>
              {group.inputs.map((input, index) => (index === 0 ? ({
                ...input,
                onClose: () => {
                  this.removeFilter(group, groupProps.id);
                  this.props.onChange({
                    target: {
                      id: input.name,
                      value: '',
                    },
                  });
                },
                isClose: true,
              }) : input)).map(this.getField)}
            </React.Fragment>
          ))}
          {(groups.length === 0 || group.list) && (
            <p>
              <a
                onClick={this.addInput(group)}
                className="align-self-start full-width"
                href="#"
              >
              + Add {group.label}
              </a>
            </p>
          )}
        </React.Fragment>
      );
    }

    return group.inputs.map(this.getField);
  }

  getField = (field) => {
    const {
      type, optional, list, ...props
    } = field;

    if (type === 'separator') {
      return (
        <VerticalSeparator
          key={field.name}
          {...props}
        />
      );
    }

    let Field;

    switch (type) {
      case 'datetime':
        Field = Fields.InputDateTimeInline;
        break;
      case 'checkbox':
        Field = Fields.Checkbox;
        break;
      case 'select':
        Field = Fields.Select;
        break;
      case 'selectSearch':
        Field = Fields.SelectSearch;
        break;
      case 'subgroup':
        return this.getGroup({ optional, list, ...props });
      case 'input':
      default:
        Field = Fields.Input;
    }

    if (optional) {
      const inputs = this.state.optionals[props.name] || [];
      return (
        <React.Fragment key={props.name}>
          {inputs.map(fieldProps => (
            <Field
              key={fieldProps.id}
              onChange={this.onChangeList(list)}
              {...props}
              name={`${props.name}[${fieldProps.id}]`}
              data-testid={props.name}
              onClose={() => {
                this.removeFilter(props, fieldProps.id, list);
                if (!list) {
                  this.props.onChange({
                    target: {
                      id: props.name,
                      value: '',
                    },
                  });
                }
              }}
              isClose
            />
          ))}
          {(inputs.length === 0 || list) && (
            <p>
              <a
                onClick={this.addInput(props, list)}
                className="align-self-start full-width"
                href="#"
              >
              + Add {props.label}
              </a>
            </p>
          )}

        </React.Fragment>
      );
    }


    return (
      <Field
        key={props.name}
        onChange={this.props.onChange}
        {...props}
      />
    );
  }

  addInput = ({ name, defaultValue }, isList) => (event) => {
    event.preventDefault();

    const { optionals, list } = this.state;
    const input = optionals[name] || [];
    const id = (new Date()).getTime();

    input.push({ id });

    if (isList) {
      const item = list[name] || [];
      this.setState({
        list: {
          ...list,
          [name]: [
            ...item,
            { id, value: '' },
          ],
        },
      });
    }
    this.setState({
      optionals: {
        ...optionals,
        [name]: input,
      },
    });

    if (defaultValue) {
      this.props.onChange({
        target: {
          id: name,
          value: defaultValue,
        },
      });
    }
  }

  removeFilter = ({ name }, id, isList) => {
    const { optionals, list } = this.state;
    const inputs = optionals[name];
    if (isList) {
      const newItem = list[name].filter(input => input.id !== id);
      const newList = {
        ...list,
        [name]: newItem,
      };
      this.setState({
        list: newList,
      });

      this.onChangeListCallBack(name, newItem);
    }
    this.setState({
      optionals: {
        ...optionals,
        [name]: inputs.filter(input => input.id !== id),
      },
    });
  }

  render() {
    return (
      <form
        className="form m3-form m3-form-light"
        onSubmit={this.props.onSubmit}
      >
        {this.props.fields.map(this.getField)}
        <Fields.Submit
          formState={this.props.formState}
          {...this.props.submitButton}
        />
      </form>
    );
  }
}

export default Form;
