import compose from '@ramda/compose';
import {
  applyToValues,
  convertSomeStringToNull,
  filterParamsCurried,
  filterValues,
  onlyNotEmptyString,
  parseQuery,
  parseBody,
  parseDatetimeValues,
  momentToTimezoneServer,
  parseResponse,
} from 'app/utils';
import { fetchWithCsrfToken } from 'app/api/fetch';

/**
 * Helper to do safe requests to django's manage
 *
 * @param {string} url
 * @param {string} [method='GET'] GET, POST, PUT, DELETE
 * @param {object} [params={}] Custom props
 * @return {Promise} It will parse the response and datetime to moment
 */
const request = (url, method = 'GET', params = {}) => fetchWithCsrfToken(url, {
  method,
  credentials: 'include',
  ...params,
})
  .then(parseResponse)
  .then(parseDatetimeValues);

const fields = ['name', 'description', 'expiresAt'];

const queryFields = ['page', 'perPage', 'filterBy', 'orderBy', 'name'];

/**
 * To get a list or a entity of customer API key
 *
 * @param {object} [{ id = '', ...query }={}]
 * @returns {Promise}
 */
function getCustomerAPIKey({ id = '', ...query } = {}) {
  let url = '/api/customer/api_key';

  if (id.length) {
    url = url.concat(`/${id}`);
  }

  if (Object.keys(query).length) {
    const qs = compose(
      parseQuery,
      filterValues(onlyNotEmptyString),
      filterParamsCurried(queryFields),
    )(query);

    url = url.concat(`?${qs}`);
  }

  return request(url);
}

/**
 * Create new API key
 *
 * @param {object} data
 * @returns {Promise}
 */
function createCustomerAPIKey(data) {
  const body = compose(
    parseBody,
    applyToValues(momentToTimezoneServer),
    filterValues(onlyNotEmptyString),
    filterParamsCurried(fields),
  )(data);

  return request('/api/customer/api_key', 'POST', { body });
}

/**
 * To Edit customer API key information
 *
 * @param {number|string} id
 * @param {Object} data
 * @returns {Promise}
 */
function putCustomerAPIKey(id, data) {
  const body = compose(
    parseBody,
    filterValues(onlyNotEmptyString),
    applyToValues(convertSomeStringToNull('Never')),
    applyToValues(momentToTimezoneServer),
    filterParamsCurried(fields),
  )(data);

  return request(`/api/customer/api_key/${id}`, 'PUT', { body });
}

/**
 * Delete the customer_api_key changing the expires_at to current date
 *
 * @param {string} id
 * @returns {Promise}
 */
function destroyCustomerAPIKey(id) {
  return request(`/api/customer/api_key/${id}`, 'DELETE');
}

/**
 * To generate new keys for access_key and secret_key
 *
 * @param {number|string} id
 * @returns {Promise}
 */
function patchCustomerAPIKey(id, data) {
  const body = compose(
    parseBody,
    filterValues(onlyNotEmptyString),
    applyToValues(convertSomeStringToNull('Never')),
    applyToValues(momentToTimezoneServer),
    filterParamsCurried(fields),
  )(data);

  return request(`/api/customer/api_key/${id}`, 'PATCH', { body });
}

export default {
  get: getCustomerAPIKey,
  create: createCustomerAPIKey,
  edit: putCustomerAPIKey,
  destroy: destroyCustomerAPIKey,
  patch: patchCustomerAPIKey,
};
