import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import BootstrapTable from 'react-bootstrap-table-next';
import LoadingState from 'app/components/loading-state';
import ControllablePagination from './controllable-pagination';

import 'styles/table/_paginated-table.scss';

const PaginatedTable = ({
  keyField,
  columns,
  data,
  pagination,
  displayPageCount,
  showPaginationOnTop,
  isInitialState,
  noDataMessage,
  childrenOnTop,
  className,
  tableClassName,
  isLoading,
  ...othersProps
}) => (
  <div className={classnames('paginated-table', className)}>
    {showPaginationOnTop && (
      <ControllablePagination pagination={pagination} displayPageCount={displayPageCount}>
        {childrenOnTop}
      </ControllablePagination>
    )}
    <div className={tableClassName}>
      <BootstrapTable
        keyField={keyField}
        columns={columns}
        data={data}
        noDataIndication={() => {
          if (isInitialState || isLoading) {
            return <LoadingState />;
          }

          return noDataMessage;
        }}
        striped
        hover
        bordered={false}
        {...othersProps}
      />
    </div>
    <ControllablePagination pagination={pagination} displayPageCount={displayPageCount} />
  </div>
);

PaginatedTable.propTypes = {
  keyField: PropTypes.string.isRequired,
  noDataMessage: PropTypes.string,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  pagination: PropTypes.shape({
    page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    perPage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    perPageOptions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      }),
    ).isRequired,
    disabled: PropTypes.bool.isRequired,
    changePage: PropTypes.func.isRequired,
    changeItemsPerPage: PropTypes.func.isRequired,
  }).isRequired,
  isInitialState: PropTypes.bool,
  displayPageCount: PropTypes.bool.isRequired,
  showPaginationOnTop: PropTypes.bool,
  childrenOnTop: PropTypes.any,
  className: PropTypes.any,
};

PaginatedTable.defaultProps = {
  noDataMessage: 'No data to display',
  isInitialState: true,
  showPaginationOnTop: false,
  childrenOnTop: null,
  className: '',
};

export default PaginatedTable;
