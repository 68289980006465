import {
  queryParams,
  parametersFormatter,
  parseResponse,
  prepareBody,
} from 'app/utils';
import { fetchWithCsrfToken } from 'app/api/fetch';


const TIMEOUT = 10000;


/*
API helper function.  This takes the endpoint relative to the API server,
builds the full URI and attaches authentication credentials.
*/
export function api(endpoint, { credentials, method, body, getMeta = false }) {
  if (credentials == undefined || credentials.endpoint == undefined) {
    return Promise.reject('No credentials');
  }
  if (credentials.error) {
    return Promise.reject('Credentials error in API: ' + credentials.error);
  }

  let authorization = 'Basic ' +
    btoa(credentials.techprefix + ':' + credentials.apikey);
  let args = {
    body: (body == undefined) ? undefined : JSON.stringify(body),
    headers: {
      'Content-Type': 'application/vnd.api+json',
      authorization,
    },
    method: method || ((body == undefined) ? 'GET' : 'POST'),
    withCredentials: true
  }
  let base = credentials.endpoint || '';

  /*
  TODO: This fetch currently updates the store with some information about
  whether we were able to reach the API.  While this is useful, we probably
  shouldn't directly access the store from here.  Instead, those store
  accesses should be configured from the top level.
  */

  return fetch(`${base}${endpoint}`, args)
    .then(parseResponse)
    .then((json) => { return getMeta ? json : json.data })
}

/*
 * BEGIN SPECIFIC IMPLEMENTATIONS
 * Let's try to keep this alphabetical
 */

export function daySummariesLifetime(args) {
  return api('/v2/message-stats', { ...args, getMeta: true })
    .then((response) => {
      if (response.errors) {
        const [error] = response.errors;
        if (error.status === 404) {
          return Promise.resolve({});
        }
        return Promise.reject(new Error('There was a problem connecting to the service'));
      }

      return Promise.resolve(response.data);
    }).catch(() => Promise.resolve({}));
}


export function mdrs_by_day(day, offset, args) {
  if (!offset) {offset = 0;}
  let mdrs = [];
  let start_date = new Date(day);
  let end_date = new Date(day);
  end_date.setDate(end_date.getDate() + 1);

  let params = {
    start_date: start_date.toISOString(),
    end_date: end_date.toISOString(),
    limit: 25,
    offset: offset
  };

  let queryItems = [];

  Object.keys(params).forEach(function (key) {
    queryItems.push(key + '=' + params[key]);
  });

  let url = '/v2/messages?' + queryItems.join('&');

  return api(url, args)
}


export function send_sms(from, to, content) {
  return api('/v2/messages', {from, to, content});
}


export function transaction(txn_id) {
  let r = /^[A-Za-z0-9\/-]+$/;
  if (typeof txn_id != "string") {
    return Promise.reject(Error(
      'api.transaction received an ID of incorrect type ' +
      typeof txn_id + ': ' + txn_id.toString()
    ));
  }
  if (!(r.test(txn_id))) {
    return Promise.reject(Error(
      'api.transaction received an invalid ID: ' + txn_id
    ));
  }
  return api('/v2/transactions/' + txn_id);
}

export function balance() {
  return fetchWithCsrfToken('/accounts/json/balance/', { method: 'GET', credentials: 'include' })
    .then(parseResponse)
}

export function customer() {
  return fetchWithCsrfToken('/accounts/json/customer/', { method: 'GET', credentials: 'include' })
    .then(parseResponse)
}

export function setPop(params) {
  const body = JSON.stringify(params);
  return fetchWithCsrfToken('/api/customer/edge_strategy', { method: 'PUT', credentials: 'include', body })
    .then(parseResponse)
}

export function resetSipPassword() {
  return fetchWithCsrfToken('/api/customer/sip_password', { method: 'POST', credentials: 'include' })
    .then(parseResponse)
}

export function createRoute(params) {
  const body = JSON.stringify(params);
  return fetchWithCsrfToken('/api/routes/inbound/', { method: 'POST', credentials: 'include', body })
    .then(parseResponse)
}

export function patchRoute(routeId, data) {
  return fetchWithCsrfToken(`/api/routes/inbound/${routeId}`, {
    method: 'PATCH',
    credentials: 'include',
    body: JSON.stringify(data),
  }).then(parseResponse);
}

export function deleteRoute(routeId) {
  return fetchWithCsrfToken(`/api/routes/inbound/${routeId}`, { method: 'DELETE', credentials: 'include' })
    .then(parseResponse);
}

export function getRoutes({ perPage, page }) {
  return fetchWithCsrfToken(
    `/api/routes/inbound?per_page=${perPage}&page=${page}`,
    { method: 'GET', credentials: 'include' }
  ).then(parseResponse)
}

export function edgeStrategies({perPage, page}) {
  return fetchWithCsrfToken(
    `/api/edge_strategies?per_page=${perPage}&page=${page}`,
    { method: 'GET', credentials: 'include' }
  ).then(parseResponse)
}


export function listAvailableAreaCodes({ limit = 25, offset = 0, max_setup_cost }) {
  const qsParams = parametersFormatter({ limit, offset, max_setup_cost });
  return api(`/v2/numbers/available/areacodes?${qsParams}`);
}

export function purchasePhoneNumber(number, { credentials, ...data }) {
  const body =  prepareBody(data);
  return api(`/v2/numbers/${number}`, {
    credentials,
    body,
  });
}

export function outboundAllowedIps({ perPage, page }) {
  return fetchWithCsrfToken(
    `/api/outbound_allowed_ips?per_page=${perPage}&page=${page}`,
    { method: 'GET', credentials: 'include' }
  ).then(parseResponse);
}

export function deleteOutboundAllowedIp(id) {
  return fetchWithCsrfToken(
    `/api/outbound_allowed_ips/${id}`,
    { method: 'DELETE', credentials: 'include' }
  ).then(parseResponse);
}

export function rateplan({ destinationPrefix='A', page=1, perPage=15, range='all' }) {
  const params = queryParams({
    destination_prefix: destinationPrefix,
    per_page: perPage,
    page,
    range,
  });
  const url = encodeURI(`/accounts/json/rateplan${params}`);
  return fetchWithCsrfToken(url, { method: 'GET', credentials: 'include' })
    .then(parseResponse);
}

export const getPortorderCosts = () => fetchWithCsrfToken(
  `/api/portorder_costs`,
  { method: 'GET', credentials: 'include' }
).then(parseResponse);
