import { makeReduxAssets } from 'resource-toolkit';
import E911 from 'app/api/e911';

const namespace = 'E911';

export const e911Resource = makeReduxAssets({
  name: namespace,
  idKey: 'id',
  expectAllMeta: true,
  gateway: {
    fetchMany: async (_, { query, credentials }) => {
      const { data, ...meta } = await E911.get({ query, credentials });
      return { data, meta };
    },
  },
});

export const { initialState } = e911Resource;

export const { actions: e911Actions } = e911Resource;

export default e911Resource.reducer;
