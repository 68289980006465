import get from 'lodash/get';
import keys from 'lodash/keys';
import { createAction } from '@reduxjs/toolkit';
import { makeReduxAssets } from 'resource-toolkit';
import PortOrdersAPI from 'app/api/port-orders';
import PortOrdersLogEntriesAPI from 'app/api/port-orders-log-entries';
import { parseDatetimeValues } from 'app/utils';
import { KIND_TO_FETCH_PER_TAB, TAB_IN_FLIGHT, PORT_ORDER_TAB_BY_STATUS } from 'app/apps/numbers-portability/constants';

export const attachTabsByKind = (portOrders, kind) => {
  const tab = keys(KIND_TO_FETCH_PER_TAB).find(key => KIND_TO_FETCH_PER_TAB[key] === kind);
  if (!tab) {
    throw new Error(`Invalid kind: ${kind}`);
  }

  return portOrders.map(it => ({ ...it, tab }));
};

export const attachInferredTab = (portOrder) => {
  const { status } = portOrder;
  const tab = get(PORT_ORDER_TAB_BY_STATUS, status);
  return { ...portOrder, tab };
};

const namespace = 'PORT_ORDERS';

export const portOrdersResource = makeReduxAssets({
  name: namespace,
  idKey: 'id',
  relatedKeys: {
    logEntries: 'many',
  },
  gateway: {
    fetchMany: async (portOrderIds, queryset) => {
      const response = await PortOrdersAPI.get(queryset);
      const parsedBody = await parseDatetimeValues(response);
      const data = get(parsedBody, 'data');
      const dataWithTabs = attachTabsByKind(data, queryset.kind);
      return dataWithTabs;
    },
    update: async (portOrderId, portOrder) => {
      const response = await PortOrdersAPI.patch(portOrderId, portOrder);
      const parsedBody = await parseDatetimeValues(response);
      const patched = { ...portOrder, ...get(parsedBody, 'data') };
      const dataWithTab = attachInferredTab(patched);
      return dataWithTab;
    },
    createRelated: async (portOrderId, relationshipKey, data) => {
      switch (relationshipKey) {
        case 'logEntries':
          return (await PortOrdersLogEntriesAPI.post(portOrderId, data).then(parseDatetimeValues)).data;
        default:
          throw new Error(`Unexpectad related create: ${portOrderId}, ${relationshipKey}`);
      }
    },
    fetchRelated: async (portOrderId, relationshipKey) => {
      switch (relationshipKey) {
        case 'logEntries':
          return (await PortOrdersLogEntriesAPI.get(portOrderId).then(parseDatetimeValues)).data;
        default:
          throw new Error(`Unexpectad related fetch: ${portOrderId}, ${relationshipKey}`);
      }
    },
    updateRelated: async (portOrderId, relationshipKey, data) => {
      switch (relationshipKey) {
        case 'logEntries':
          return (await PortOrdersLogEntriesAPI.patch(portOrderId, data).then(parseDatetimeValues)).data;
        default:
          throw new Error(`Unexpectad related update: ${portOrderId}, ${relationshipKey}`);
      }
    },
  },
});

export const initialState = {
  ...portOrdersResource.initialState,
  selectedTab: TAB_IN_FLIGHT,
};

const actions = {
  ...portOrdersResource.actions,
  changeTab: createAction(`${namespace}/CHANGE_TAB`),
};

export const portOrdersActions = actions;

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case portOrdersResource.actionType:
      return portOrdersResource.reducer(state, action);
    case actions.changeTab.type:
      return { ...state, selectedTab: action.payload };
    default:
      return state;
  }
}
