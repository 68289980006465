import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class CustomerTimezone extends React.Component {
  static propTypes = {
    customer: PropTypes.shape({
      timezone: PropTypes.string,
    }),
    render: PropTypes.func.isRequired,
  };

  static defaultProps = {
    customer: {
      timezone: '',
    },
  };

  render() {
    const timezone = this.props.customer.timezone || window.TIME_ZONE;

    return this.props.render({ timezone });
  }
}

const stateToProps = ({ customer }) => ({ customer });

export default connect(stateToProps)(CustomerTimezone);
