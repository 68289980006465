import React from 'react';
import moment from 'moment';
import CustomerTimezone from 'app/hoc/CustomerTimezone';

const formatterCustomerDate = format => field => (
  field instanceof moment ? (
    <CustomerTimezone
      render={({ timezone }) => (
        field.clone().tz(timezone).format(format)
      )}
    />
  ) : field
);

export default formatterCustomerDate;
