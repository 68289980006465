import { makeSystemClient } from 'app/api/fetch';

const client = makeSystemClient('admins', {
  extraKeyFormat: (key) => {
    if (key === 'password_1') {
      return 'password1';
    }

    if (key === 'password_2') {
      return 'password2';
    }

    return key;
  },
});

export default client;
