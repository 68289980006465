import { makeReduxAssets } from 'resource-toolkit';
import DynamicRouteLocations from 'app/api/dynamic-route-locations';

const namespace = 'DYNAMIC_ROUTE_LOCATIONS';

export const dynamicRouteLocationsResource = makeReduxAssets({
  name: namespace,
  idKey: 'id',
  expectAllMeta: true,
  gateway: {
    fetchMany: (dumb, drouteId, data) => DynamicRouteLocations.get(drouteId, data),
    create: (drouteId, data) => DynamicRouteLocations.post(drouteId, data),
    delete: (locationId, drouteId) => DynamicRouteLocations.delete(drouteId, locationId),
  },
});

export const initialState = dynamicRouteLocationsResource.initialState;

export const dynamicRouteLocationsActions = dynamicRouteLocationsResource.actions;

export default dynamicRouteLocationsResource.reducer;
