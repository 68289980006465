import {
  flatResponse, parametersFormatter, prepareBody,
} from 'app/utils';
import { api } from 'app/api/index';

function getE911({ query, credentials }) {
  const queryString = parametersFormatter(prepareBody(query));
  return api(`/v2/e911s?${queryString}`, { credentials, getMeta: true })
    .then(flatResponse);
}


function deleteE911(id, credentials) {
  return api(
    `/v2/e911s/${id}`,
    { method: 'DELETE', credentials },
  )
}

export default {
  get: getE911,
  delete: deleteE911,
};
