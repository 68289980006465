import AccountCNAMs from 'app/api/account-cnams';
import resourceActionsFactory, {
  REQUESTED,
  FAILED,
  SUCEEDED as SUCCEEDED,
  CLEAR_MESSAGE,
  methods,
} from 'actions/resource';

export const { dispatchers: action, actionMap: types } = resourceActionsFactory({
  [REQUESTED]: 'ACCOUNT_CNAMS/REQUESTED',
  [FAILED]: 'ACCOUNT_CNAMS/FAILED',
  [SUCCEEDED]: 'ACCOUNT_CNAMS/SUCCEEDED',
  [CLEAR_MESSAGE]: 'ACCOUNT_CNAMS/CLEAR_MESSAGE',
});

const requested = action[REQUESTED];
const succeeded = action[SUCCEEDED];
const failed = action[FAILED];

export const clearAccountCNAMsMessage = action[CLEAR_MESSAGE];

export function fetchAccountCNAMs(query) {
  return (dispatch, getState) => {
    const { credentials } = getState();

    dispatch(requested(null, { message: '' }));

    return AccountCNAMs.get({ credentials, ...query })
      .then(response => dispatch(succeeded(response)))
      .catch(error => dispatch(failed(error.message)));
  };
}

export function createAccountCNAM({ value }) {
  return (dispatch, getState) => {
    const { credentials, accountCNAMs } = getState();

    dispatch(requested(null));

    return AccountCNAMs.post({
      credentials,
      data: { value },
    }).then(response => dispatch(succeeded({
      ...accountCNAMs.response,
      data: [response.data],
      message: `Created "${response.data.attributes.value}"`,
    }, null, {
      id: response.data.id,
      method: methods.CREATED,
    }))).catch(error => dispatch(failed(error.message)));
  };
}

export function editAccountCNAM(updatedCnam) {
  return (dispatch, getState) => {
    const { credentials, accountCNAMs } = getState();

    dispatch(requested(updatedCnam.id));

    return AccountCNAMs.patch({
      credentials,
      id: updatedCnam.id,
      data: updatedCnam,
    }).then(response => dispatch(succeeded({
      ...accountCNAMs.response,
      message: `Successfully updated to "${response.data.attributes.value}"`,
    }, response.data.id, {
      method: methods.UPDATED,
      updates: response.data,
    }))).catch(error => dispatch(failed(error.message)));
  };
}

export function deleteAccountCNAM(id) {
  return (dispatch, getState) => {
    const { credentials } = getState();

    dispatch(requested(id));

    return AccountCNAMs.delete({
      credentials,
      id,
    }).then(response => dispatch(succeeded({
      data: [response.data],
      message: 'CNAM deleted',
    }, id, {
      method: methods.DELETED,
    }))).catch(error => dispatch(failed(error.message)));
  };
}
