/*
  File for externalized config that may change semi-frequently, but does not make
  sense to keep in Redux, component state, etc.

  See also:
  settings/base.py has config for Django
  http://gitlab.internal/website/manage/-/merge_requests/1133 (edited)
  http://gitlab.internal/website/manage/-/merge_requests/1135
 */

export const RETIRED_POP_IDS = [1, 5];
export const CHANGING_POP_IDS = [];
export const UPDATED_POP_IDS = []
