import React from 'react';

const VerticalSeparator = ({ label }) => (
  <div className="separator">
    { label
        ? (
          <React.Fragment>
            <label>{label}</label>
            <hr />
          </React.Fragment>
        ) : (
          <hr />
        )
    }
  </div>
);

export default VerticalSeparator;
