import React, { Fragment } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import 'styles/_help-tooltip.scss';

const LinkTooltip = ({ id, tooltip, className }) => (
  <a className={classNames('help-tooltip', className)} tabIndex={0} aria-label={tooltip.replace("<br />"," ")} aria-describedby={id} data-tip={tooltip} data-for={id}>
    <i className="fa fa-question" />
  </a>
);

LinkTooltip.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  tooltip: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
};

const HelpTooltip = ({
  id, tooltip, className, children, place='top'
}) => (
  <Fragment>
    <ReactTooltip multiline role="tooltip" place={place} className={classNames('help-tooltip-content', className)} id={id} />
    {!children && (
      <LinkTooltip id={id} tooltip={tooltip} className={className} />
    )}
    {children && (
      <span>
        <span style={{ marginRight: '5px' }}>
          {children}
        </span>
        <LinkTooltip id={id} tooltip={tooltip} className={className} />
      </span>
    )}
  </Fragment>
);

HelpTooltip.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  tooltip: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.any
};

HelpTooltip.defaultProps = {
  className: '',
  children: null,
};

export default HelpTooltip;
