import { fetchWithCsrfToken } from 'app/api/fetch';
import {
  parseResponse,
  parseBody,
  parseQuery,
} from 'app/utils';

function getCNAMApprovals(querySet = {}) {
  return fetchWithCsrfToken(`/api/cnam_preset?${parseQuery(querySet)}`, {
    method: 'GET',
  }).then(parseResponse);
}

function editCNAMApprovals(id, data) {
  return fetchWithCsrfToken(`/api/cnam_preset/${id}`, {
    method: 'PUT',
    body: parseBody(data),
  }).then(parseResponse);
}

export default {
  get: getCNAMApprovals,
  put: editCNAMApprovals,
};
