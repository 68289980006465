import { fetchWithCsrfToken } from 'app/api/fetch';
import {
  parseResponse,
  parseBody,
} from 'app/utils';

function getLogEntries(portOrderId) {
  return fetchWithCsrfToken(`/system/api/port_orders/${portOrderId}/log_entries`, {
    method: 'GET',
  }).then(parseResponse);
}

function createLogEntry(portOrderId, data = {}) {
  return fetchWithCsrfToken(`/system/api/port_orders/${portOrderId}/log_entries`, {
    method: 'POST',
    body: parseBody(data),
  }).then(parseResponse);
}

function editLogEntry(portOrderId, data = {}) {
  return fetchWithCsrfToken(`/system/api/port_orders/${portOrderId}/log_entries/${data.id}`, {
    method: 'PATCH',
    body: parseBody(data),
  }).then(parseResponse);
}

export default {
  get: getLogEntries,
  post: createLogEntry,
  patch: editLogEntry,
};
