import * as API from 'app/api';
import Numbers from 'app/api/numbers';
import { fetchBalance } from 'app/redux/actions/balance';
import resourceActionsFactory, {
  REQUESTED,
  FAILED,
  SUCEEDED,
} from 'actions/resource';

export const DID_CHANGE_FIELD = 'DID_CHANGE_FIELD';
export const ADD_NUMBER_FILTER = 'ADD_NUMBER_FILTER';
export const REMOVE_NUMBER_FILTER = 'REMOVE_NUMBER_FILTER';
export const PURCHASE_REQUESTED = 'PURCHASE_REQUESTED';
export const PURCHASE_FAILED = 'PURCHASE_FAILED';
export const PURCHASE_SUCEEDED = 'PURCHASE_SUCEEDED';
export const CLEAR_RESPONSE = 'CLEAR_RESPONSE';

export function didChangeField(fieldName, fieldValue) {
  return {
    type: DID_CHANGE_FIELD,
    fieldName,
    fieldValue,
  };
}

const {
  dispatchers, actionMap,
} = resourceActionsFactory({
  [REQUESTED]: 'DID_REQUESTED',
  [FAILED]: 'DID_FAILED',
  [SUCEEDED]: 'DID_SUCEEDED',
});

export const didActions = actionMap;
export const didRequested = dispatchers[REQUESTED];
export const didFailed = dispatchers[FAILED];
export const didSuceeded = dispatchers[SUCEEDED];

export function addNumberFilter() {
  return {
    type: ADD_NUMBER_FILTER,
  };
}

export function removeNumberFilter(index) {
  return {
    type: REMOVE_NUMBER_FILTER,
    index,
  };
}

const {
  dispatchers: purchaseDispatchers, actionMap: purchaseActionMap,
} = resourceActionsFactory({
  [REQUESTED]: 'DID_PURCHASE_REQUESTED',
  [FAILED]: 'DID_PURCHASE_FAILED',
  [SUCEEDED]: 'DID_PURCHASE_SUCEEDED',
});

export const purchaseDidActions = purchaseActionMap;
export const purchaseRequested = purchaseDispatchers[REQUESTED];
export const purchaseSuceeded = purchaseDispatchers[SUCEEDED];
export const purchaseFailed = purchaseDispatchers[FAILED];

export function clearResponse(responseKey) {
  return {
    type: CLEAR_RESPONSE,
    payload: {
      responseKey,
    },
  };
}

export function searchNumbersV2(data) {
  return (dispatch, getState) => {
    const { credentials } = getState();
    dispatch(didRequested());

    return Numbers.available({ ...data, credentials })
      .then(response => dispatch(didSuceeded(response, 'did')))
      .catch(error => dispatch(didFailed(error, 'did')));
  };
}

export function purchaseNumber({ number, ...params }) {
  return (dispatch, getState) => {
    const { credentials } = getState();

    dispatch(purchaseRequested(number));

    return API.purchasePhoneNumber(number, { credentials, ...params })
      .then(() => {
        dispatch(fetchBalance(true, true));
        dispatch(purchaseSuceeded(null, number));
      }).catch((error) => {
        dispatch(purchaseFailed(error, number));
        return Promise.reject(error);
      });
  };
}
