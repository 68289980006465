import React from 'react';
import PropTypes from 'prop-types';
import LabeledSelect from 'app/components/labeled-select';
import Pagination from 'app/components/table/pagination';
import 'styles/table/_paginated-table.scss';

const ControllablePagination = ({ pagination, displayPageCount, children }) => (
  <div className="paginated-table-footer">
    <LabeledSelect
      className="form-inline items-per-page"
      label="Items per page:"
      value={pagination.perPage}
      options={pagination.perPageOptions}
      disabled={pagination.disabled}
      onChange={pagination.changeItemsPerPage}
    />
    {children}
    <Pagination
      { ...pagination } displayPageCount={displayPageCount} rightAligned
    />
  </div>
);

ControllablePagination.propTypes = {
  pagination: PropTypes.shape({
    page: PropTypes.number.isRequired,
    changePage: PropTypes.func.isRequired,
    perPage: PropTypes.number.isRequired,
    perPageOptions: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    })).isRequired,
    disabled: PropTypes.bool.isRequired,
    changeItemsPerPage: PropTypes.func.isRequired,
  }).isRequired,
  displayPageCount: PropTypes.bool,
  children: PropTypes.any,
};

ControllablePagination.defaultProps = {
  displayPageCount: false,
  children: null,
};

export default ControllablePagination;
