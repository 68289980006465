import {
  parseResponse,
  parseBody,
  parseDatetimeValues,
} from 'app/utils';
import { fetchWithCsrfToken } from 'app/api/fetch';

function getProfileMessages() {
  return fetchWithCsrfToken(`/${window.SYS_LOGIN_PATH}/api/profile_messages`, {
    method: 'GET', credentials: 'include',
  }).then(parseResponse)
    .then(parseDatetimeValues);
}

function createProfileMessage(profileMessage) {
  return fetchWithCsrfToken(`/${window.SYS_LOGIN_PATH}/api/profile_messages`, {
    method: 'POST', credentials: 'include', body: parseBody(profileMessage),
  }).then(parseResponse)
    .then(parseDatetimeValues);
}

function editProfileMessage(id, profileMessage) {
  return fetchWithCsrfToken(`/${window.SYS_LOGIN_PATH}/api/profile_messages/${id}`, {
    method: 'PUT', credentials: 'include', body: parseBody(profileMessage),
  }).then(parseResponse)
    .then(parseDatetimeValues);
}

function destroyProfileMessage(id) {
  return fetchWithCsrfToken(`/${window.SYS_LOGIN_PATH}/api/profile_messages/${id}`, {
    method: 'DELETE',
  }).then(parseResponse);
}

export default {
  get: getProfileMessages,
  create: createProfileMessage,
  edit: editProfileMessage,
  destroy: destroyProfileMessage,
};
