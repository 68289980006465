import { combineReducers } from 'redux';
import accountCNAMs from './account-cnams';
import balance from './balance';
import cdrsExports from './cdrs-exports';
import cnamPresets from './cnam-preset';
import countries from './countries';
import credentials from './credentials-reducer';
import customer from './customer';
import customerAPIKey from './customer-api-key';
import customerNotes from './customer-notes-reducer';
import did from './did';
import didHistory from './did-history';
import profileMessage from './profile-message-reducer';
import profileSelector from './profiles';
import rateplan from './rateplan';
import dynamicRoutes from '../ducks/dynamic-routes';
import dynamicRouteLocations from '../ducks/dynamic-route-locations';
import edgeStrategies from '../ducks/edge-strategies';
import e911 from '../ducks/e911';
import inboundRoutes from '../ducks/inbound-routes';
import { dayMdrs, daySummaries } from './messaging-stats';
import outboundAllowedIps from './outbound-allowed-ips';
import portOrders from '../ducks/port-orders';
import admins from '../ducks/admins';
import userGroups from '../ducks/user-groups';
import portorderCosts from '../ducks/portorder-costs';
import cdrs from '../ducks/cdrs';

const singleReducer = combineReducers({
  accountCNAMs,
  balance,
  cdrs,
  cdrsExports,
  cnamPresets,
  countries,
  credentials,
  customer,
  customerAPIKey,
  customerNotes,
  dayMdrs,
  daySummaries,
  did,
  didHistory,
  dynamicRoutes,
  dynamicRouteLocations,
  edgeStrategies,
  e911,
  inboundRoutes,
  outboundAllowedIps,
  portOrders,
  profileMessage,
  profileSelector,
  rateplan,
  admins,
  userGroups,
  portorderCosts,
});

export default singleReducer;
