/* eslint-disable consistent-return */
import isNumber from 'lodash/isNumber';

const numberOrNullProp = (props, propsName) => {
  const { [propsName]: data } = props;

  if (data !== null && !isNumber(data)) {
    return new Error(`Please provide a ${propsName} number or null!`);
  }
};

export default numberOrNullProp;
