import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './button.scss';

/**
 * Button Component
 *
 * @param {any} { children, onClick, props }
 */
const Button = ({
  children,
  onClick,
  success,
  link,
  danger,
  warning,
  small,
  disabled,
  className,
  block,
  ...props
}) => (
  <button
    type="button"
    className={classnames(
      'button-v2',
      {
        'button-v2--success': success,
        'button-v2--link': link,
        'button-v2--warning': warning,
        'button-v2--danger': danger,
        'button-v2--small': small,
        'button-v2--block': block,
      },
      className,
    )}
    onClick={onClick}
    disabled={disabled}
    {...props}
  >
    {children}
  </button>
);

Button.propTypes = {
  children: PropTypes.any.isRequired,
  success: PropTypes.bool,
  link: PropTypes.bool,
  small: PropTypes.bool,
  warning: PropTypes.bool,
  danger: PropTypes.bool,
  block: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.any,
};

Button.defaultProps = {
  onClick: () => {},
  success: false,
  link: false,
  small: false,
  warning: false,
  danger: false,
  block: false,
  disabled: false,
  className: '',
};

export default Button;
