import React from 'react';
import PropTypes from 'prop-types';

import 'styles/alert/_alert.scss';

const CloseButton = ({ onClose }) => (
  <div className="close-button">
    <i className="fa fa-times fa-lg" onClick={onClose} />
  </div>
);

CloseButton.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export const alertTypes = {
  DANGER: 'danger',
  INFO: 'info',
};

const Alert = ({ message, type, onClose }) => {
  return (
    <div className={`react-alert react-alert-${type}`}>
      <p className="message">{message}</p>
      {onClose && <CloseButton onClose={onClose} />}
    </div>
  );
}

Alert.propTypes = {
  message: PropTypes.any,
  type: PropTypes.string,
  onClose(props, propName, componentName) {
    const prop = props[propName];
    if (typeof prop !== 'function' && prop !== null) {
      return new Error(`Please provide the ${propName} prop as a function on ${componentName}!`);
    }
    return false;
  },
};

Alert.defaultProps = {
  type: alertTypes.INFO,
  message: null,
  onClose: null,
};

export default Alert;
