import React from 'react';
import PropTypes from 'prop-types';
import groupBy from 'lodash/groupBy';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import * as Fields from 'app/components/segment/fields';
import { edgeStrategiesActions } from 'ducks/edge-strategies';

export const ToggleableEdgeDescriptions = ({ onToggle, isOpen }) => (
  <div className="edgestrategy-description">
    {isOpen ? (
      <a href="#" onClick={() => onToggle(false)}>
        <i className="fa fa-chevron-up"></i> Hide firewall rules
      </a>
    ) : (
      <a href="#" onClick={() => onToggle(true)}>
        <i className="fa fa-chevron-down"></i> Show firewall rules
      </a>
    )}
    {isOpen && (
      <EdgeStrategiesTables />
    )}
  </div>
);

ToggleableEdgeDescriptions.propTypes = {
  onToggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export class DumbEdgeStrategySelector extends React.PureComponent {
  static propTypes = {
    edgeStrategies: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })).isRequired,
      isLoading: PropTypes.bool.isRequired,
    }).isRequired,
    actions: PropTypes.shape({
      edgeStrategies: PropTypes.shape({
        readAll: PropTypes.func.isRequired,
      }).isRequired,
    }).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    className: PropTypes.string,
  }

  static defaultProps = {
    value: null,
    disabled: false,
    label: 'Edge Strategy',
    className: '',
  }

  constructor(props) {
    super(props);

    if (this.props.edgeStrategies.items.length === 0 && !this.props.edgeStrategies.isLoading) {
      this.props.actions.edgeStrategies.readAll({ page: 1, perPage: 50 });
    }
  }

  getOptions = () => this.props.edgeStrategies.items.map(strategy => ({
    value: strategy.id,
    label: strategy.name,
  }))

  render() {
    return (
      <Fields.Select
        name="edgeSetStrategy"
        aria-label="Edge Strategy"
        disabled={this.props.disabled || this.props.edgeStrategies.isLoading}
        value={this.props.value}
        onChange={this.props.onChange}
        tooltip="The Edge Strategy for your Route. You can specify a different PoP for each route."
        label={`${this.props.label}${this.props.edgeStrategies.isLoading ? ' (Loading...)' : ''}`}
        defaultOption="-- Please select --"
        options={this.getOptions()}
        className={this.props.className}
      />
    );
  }
}

const getStrategiesByRegion = edgeStrategies => groupBy(edgeStrategies, 'preferredPop.region');

export class DumbEdgeStrategiesTables extends React.PureComponent {
  static propTypes = {
    edgeStrategies: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      })).isRequired,
      isLoading: PropTypes.bool.isRequired,
    }).isRequired,
    actions: PropTypes.shape({
      edgeStrategies: PropTypes.shape({
        readAll: PropTypes.func.isRequired,
      }).isRequired,
    getPopNameForId: PropTypes.func
    }).isRequired
  }

  constructor(props) {
    super(props);

    if (this.props.edgeStrategies.items.length === 0 && !this.props.edgeStrategies.isLoading) {
      this.props.actions.edgeStrategies.readAll({ page: 1, perPage: 50 });
    }
  }

  getColumns = () => this.props.getPopNameForId
    ? [
        {
          text: 'PoP',
          dataField: 'name',
          formatter: (value, row) => this.props.getPopNameForId(row.id, value),
        },
        {
          text: 'IP Addresses',
          dataField: 'firewallRules'
        }
      ]
    : [
        {
          text: 'PoP',
          dataField: 'name'
        },
        {
          text: 'IP Addresses',
          dataField: 'firewallRules'
        }
      ];

  render() {
    if (this.props.edgeStrategies.isLoading) {
      return (
        <div className="edge-strategies-tables">
          <p><i className="fa fa-spin fa-spinner" /> Loading edge strategies details...</p>
        </div>
      );
    }

    const strategiesByRegion = getStrategiesByRegion(this.props.edgeStrategies.items);

    return (
      <div className="edge-strategies-tables">
        {Object.keys(strategiesByRegion).map(region => (
          <BootstrapTable
            key={region}
            keyField="id"
            caption={<strong>{region}</strong>}
            columns={this.getColumns()}
            data={strategiesByRegion[region]}
            noDataIndication="No available data found."
            bordered={false}
            hover
          />
        ))}
      </div>
    );
  }
}

const mapState = ({ edgeStrategies }) => ({
  edgeStrategies,
});

const mapDispatch = dispatch => ({
  actions: {
    edgeStrategies: bindActionCreators(edgeStrategiesActions, dispatch),
  },
});

export const EdgeStrategySelector = connect(mapState, mapDispatch)(DumbEdgeStrategySelector);

export const EdgeStrategiesTables = connect(mapState, mapDispatch)(DumbEdgeStrategiesTables);
