import { makeReduxAssets } from 'resource-toolkit';
import * as CommonAPI from 'app/api';

const namespace = 'EDGE_STRATEGIES';

export const edgeStrategiesResource = makeReduxAssets({
  name: namespace,
  idKey: 'id',
  expectAllMeta: true,
  gateway: {
    fetchMany: (_, query) => CommonAPI.edgeStrategies(query),
  },
});

export const initialState = edgeStrategiesResource.initialState;

export const edgeStrategiesActions = edgeStrategiesResource.actions;

export default edgeStrategiesResource.reducer;
