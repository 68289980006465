import React from 'react';
import sanitizeHtml from 'sanitize-html';
import PropTypes from 'prop-types';
import none from '@ramda/none';

const forceAbsoluteHref = url => {
  const prefixes = ['http://', 'https://', '//']

  return none((prefix) => url.startsWith(prefix), prefixes)
    ? '//' + url
    : url
}

const clean = dirty => sanitizeHtml(dirty, {
  allowedTags: ['b', 'i', 'em', 'strong', 'a'],
  allowedAttributes: {
    a: ['href'],
  },
  transformTags: {
    'a': (tagName, attribs) => attribs.href
      ? { tagName, attribs: { ...attribs, href: forceAbsoluteHref(attribs.href) } }
      : { tagName, attribs }
  }
});

const ShowHTML = ({ isHTML, children }) => (
  isHTML ? (
    <span dangerouslySetInnerHTML={{ __html: clean(children) }} />
  ) : (
    <span>{children}</span>
  )
)

ShowHTML.propTypes = {
  isHTML: PropTypes.bool,
  children: PropTypes.any.isRequired,
};

ShowHTML.defaultProps = {
  isHTML: true,
};

export default ShowHTML;
