import React from 'react';
import Switch from 'react-switch';
import PropTypes from 'prop-types';
import './switch-button.scss';

const SwitchButton = ({ textOn, textOff, ...props }) => (
  <div className="switch-button">
    <label className="switch-button__label">
      <Switch onColor="#01adc9" offColor="#e74c3c" {...props} />
      <span className="switch-button__text">{props.checked ? textOn : textOff}</span>
    </label>
  </div>
);

SwitchButton.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  textOn: PropTypes.string,
  textOff: PropTypes.string,
};

SwitchButton.defaultProps = {
  checked: false,
  onChange: () => {},
  textOn: 'Enabled',
  textOff: 'Disabled',
};

export default SwitchButton;
