import React, { Component } from 'react';
import SubmitButton, { formStates } from 'app/components/submit-button';
import TextArea from 'app/components/textarea';
import PropTypes from 'prop-types';

import './add-note-form.scss';

class AddNoteForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      isCreating: false,
    };
    this.onInput = this.onInput.bind(this);
    this.submit = this.submit.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.response.isError) {
      return {
        ...prevState,
        isCreating: false,
      };
    }

    return prevState;
  }

  onInput(e) {
    this.setState({
      value: e.target.value,
    });
  }

  getFormState() {
    if (this.state.isCreating) {
      return formStates.SENDING;
    }

    if (!this.state.value) {
      return formStates.NOT_READY;
    }

    return formStates.READY;
  }

  async submit(e) {
    if (e) {
      e.preventDefault();
    }
    this.setState({
      isCreating: true,
    });
    await this.props.submit(this.state.value);
    this.setState({
      isCreating: false,
      value: '',
    });
  }

  render() {
    return (
      <form onSubmit={this.submit} className="note-form-add">
        <h3 className="note-form-add__title">Add Note</h3>
        <div className="note-form-add__group">
          <TextArea
            value={this.state.value}
            onInput={this.onInput}
            minRows={2}
            disabled={this.props.isLoading}
            className="note-form-add__input form-control form-control-lg"
            submit={this.submit}
          />
        </div>
        <div className="note-form-add__footer">
          <p className="note-form-add__helper">Use shit+enter to add multline or esc to cancel</p>
          <SubmitButton formState={this.getFormState()} text="Add Note" />
        </div>
      </form>
    );
  }
}

AddNoteForm.propTypes = {
  submit: PropTypes.func.isRequired,
  response: PropTypes.any,
  isLoading: PropTypes.bool,
};

AddNoteForm.defaultProps = {
  isLoading: false,
  response: {},
};

export default AddNoteForm;
