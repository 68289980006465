import { store } from "app/redux/store";
import { makeReduxAssets } from "resource-toolkit";
import CDRs from "app/api/cdrs";

export const cdrsResource = makeReduxAssets({
  name: "CDRs",
  idKey: "id",
  expectAllMeta: true,
  gateway: {
    fetchMany: async (_, query) => {
      const { credentials } = store.getState();
      const response = await CDRs.get({ query, credentials });
      const { data, ...meta } = response;

      /* istanbul ignore next */ 
      return {
        data: data.map((cdr) => ({
          ...cdr,
          id: cdr.id + cdr.startTime + cdr.endTime,
        })),
        meta
      };
    },
  },
});

export const { initialState } = cdrsResource;
export const cdrsActions = cdrsResource.actions;
export default cdrsResource.reducer;
