import { makeReduxAssets, makeDefaultMessageText } from 'resource-toolkit';
import Admins from 'app/api/admins';

export const adminsResource = makeReduxAssets({
  name: 'ADMINS',
  idKey: 'id',
  expectAllMeta: true,
  gateway: {
    fetchMany: (_, query) => Admins.get(query),
    update: async (adminId, adminData) => {
      const response = await Admins.patch(adminId, adminData);
      return response.data;
    },
    create: async adminData => {
      const response = await Admins.post(adminData)
      return response.data;
    },
  },
});

export const { initialState } = adminsResource;
export const adminsActions = adminsResource.actions;
export default adminsResource.reducer;
