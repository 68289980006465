export const PORT_ORDER_ACCEPTED = 'A';
export const PORT_ORDER_ABANDONED = 'B';
export const PORT_ORDER_COMPLETED = 'C';
export const PORT_ORDER_PENDING = 'E';
export const PORT_ORDER_HARD_CANCELED = 'H';
export const PORT_ORDER_PROCESSING_ABANDONED = 'N';
export const PORT_ORDER_PROCESSING = 'P';
export const PORT_ORDER_REJECT = 'R';
export const PORT_ORDER_SUBMITTED = 'S';
export const PORT_ORDER_REVIEW = 'W';
export const PORT_ORDER_CANCELED = 'X';

export const PORT_ORDER_VALID_TRANSITIONS = {
  [PORT_ORDER_ACCEPTED]: [
    PORT_ORDER_ACCEPTED,
    PORT_ORDER_COMPLETED,
    PORT_ORDER_HARD_CANCELED,
    PORT_ORDER_REJECT,
  ],
  [PORT_ORDER_PENDING]: [
    PORT_ORDER_PROCESSING,
    PORT_ORDER_CANCELED,
  ],
  [PORT_ORDER_PROCESSING_ABANDONED]: [
    PORT_ORDER_ABANDONED,
    PORT_ORDER_REJECT,
  ],
  [PORT_ORDER_PROCESSING]: [
    PORT_ORDER_PENDING,
    PORT_ORDER_REJECT,
    PORT_ORDER_SUBMITTED,
  ],
  [PORT_ORDER_REJECT]: [
    PORT_ORDER_PENDING,
    PORT_ORDER_PROCESSING_ABANDONED,
    PORT_ORDER_REVIEW,
  ],
  [PORT_ORDER_SUBMITTED]: [
    PORT_ORDER_ACCEPTED,
    PORT_ORDER_REJECT,
  ],
  [PORT_ORDER_REVIEW]: [
    PORT_ORDER_REVIEW,
    PORT_ORDER_REJECT,
  ],
};

export const PORT_ORDER_LABELS = {
  [PORT_ORDER_ACCEPTED]: 'Accepted',
  [PORT_ORDER_ABANDONED]: 'Abandoned',
  [PORT_ORDER_COMPLETED]: 'Completed',
  [PORT_ORDER_PENDING]: 'Pending',
  [PORT_ORDER_HARD_CANCELED]: 'Hard Canceled',
  [PORT_ORDER_PROCESSING_ABANDONED]: 'Processing Abandoned',
  [PORT_ORDER_PROCESSING]: 'Processing',
  [PORT_ORDER_REJECT]: 'Rejected',
  [PORT_ORDER_SUBMITTED]: 'Submitted',
  [PORT_ORDER_REVIEW]: 'Review',
  [PORT_ORDER_CANCELED]: 'Canceled',
};

export const CHANGE_TAB = 'PORT_ORDERS/CHANGE_TAB';
export const TAB_IN_FLIGHT = 'In Flight';
export const TAB_NEW = 'New';
export const TAB_ABANDONED = 'Abandoned';
export const TAB_REVIEW = 'Review';
export const TAB_APPROVED = 'Approved';
export const KIND_TO_FETCH_PER_TAB = {
  [TAB_IN_FLIGHT]: 'in-flight',
  [TAB_NEW]: 'new',
  [TAB_ABANDONED]: 'abandoned',
  [TAB_REVIEW]: 'review',
  [TAB_APPROVED]: 'approved',
};
export const PORT_ORDERS_TABS = Object.keys(KIND_TO_FETCH_PER_TAB);

export const PORT_ORDER_TAB_BY_STATUS = {
  [PORT_ORDER_SUBMITTED]: TAB_IN_FLIGHT,
  [PORT_ORDER_PROCESSING]: TAB_NEW,
  [PORT_ORDER_ABANDONED]: TAB_ABANDONED,
  [PORT_ORDER_REVIEW]: TAB_REVIEW,
  [PORT_ORDER_ACCEPTED]: TAB_APPROVED,
};
