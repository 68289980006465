import * as rateplanActions from 'actions/rateplan';
import mapKeys from 'lodash/mapKeys';
import values from 'lodash/values';
import camelCase from 'lodash/camelCase';

export const filterOptions = {
  ALL: 'all',
  CURRENT: 'current',
  CHANGES: 'next'
}

export const initialState = {
  isInitialState: true,
  isLoading: false,
  pageUrl: '/accounts/billing/pricing/outbound',
  filterOptions,
  range: filterOptions.ALL,
  rateplan: {
    destinationPrefix: 'A',
    page: 1,
    perPage: 300
  }
};

const rateplan = (state = initialState, action) => {
  switch (action.type) {
    case rateplanActions.RATEPLAN_RANGE_UPDATED:
      return {
        ...state,
        range: action.range
      }
    case rateplanActions.RATEPLAN_SUCCEEDED:
      const rateplan = action.payload;
      rateplan.items = rateplan.items.map(row => (
        { ...row, key: String( [...values(row)] )}
      ));
      return {
        ...state,
        isInitialState: false,
        isLoading: false,
        rateplan
      };
    case rateplanActions.RATEPLAN_REQUESTED:
      return { ...state, isLoading: true, requestError: null };
    case rateplanActions.RATEPLAN_FAILED:
      return { ...state, isLoading: false, requestError: action.error, isInitialState: false };
    default:
      return state
  }
}

export default rateplan;
