/* eslint-disable react/button-has-type */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import numberOrNullProp from 'app/types/number-or-null-prop';

import 'styles/_pagination-stepper.scss';

function renderLoadingState(isLoading) {
  const classes = classNames(
    'loading-icon',
    {
      hidden: !isLoading,
    },
  );
  return (
    <span className={classes}>
      <i className="fa fa-spin fa-spinner" />
    </span>
  );
}

const PaginationStepper = ({ displayPageCount, ...props }) => (
  <div className={classNames('pagination pagination-thin pagination-light', {
    'pagination-right': props.rightAligned,
  })}
  >
    <button
      onClick={props.prevPageFunc}
      className="btn btn-small"
      disabled={(props.currentPage === 1 || props.isLoading)}
    >
      Prev
    </button>
    <span className="pagination-text">
      Page {props.currentPage} {displayPageCount && props.totalPages ? `of ${props.totalPages}` : null}
    </span>

    <button
      onClick={props.nextPageFunc}
      className="btn btn-small"
      disabled={(
        props.totalPages === null || props.totalPages === props.currentPage || props.isLoading
      )}
    >
      Next
    </button>
    {renderLoadingState(props.isLoading)}
  </div>
);

PaginationStepper.propTypes = {
  currentPage: PropTypes.number.isRequired,
  // eslint-disable-next-line consistent-return
  totalPages(props, propName) {
    if (props.displayPageCount) {
      return numberOrNullProp(props, propName);
    }
  },
  isLoading: PropTypes.bool.isRequired,
  prevPageFunc: PropTypes.func.isRequired,
  nextPageFunc: PropTypes.func.isRequired,
  rightAligned: PropTypes.bool,
  displayPageCount: PropTypes.bool,
};

PaginationStepper.defaultProps = {
  displayPageCount: false,
  rightAligned: false,
  totalPages: null,
};

export default PaginationStepper;
