import React from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import classnames from 'classnames';
import ShowHTML from 'components/render-utils/show-html';
import 'react-datetime/css/react-datetime.css';
import './input-inline.scss';

export const InputInline = ({
  isEditing,
  defaultValue,
  isHTML,
  ...othersProps
}) => (
  isEditing ? (
    <input
      value={defaultValue}
      {...othersProps}
    />
  ) : (
    <ShowHTML isHTML>
      {defaultValue}
    </ShowHTML>
  )
);

InputInline.propTypes = {
  type: PropTypes.string,
  isHTML: PropTypes.bool,
  isEditing: PropTypes.bool,
  defaultValue: PropTypes.any.isRequired,
};

InputInline.defaultProps = {
  isHTML: false,
  isEditing: true,
  type: 'text',
};

export class InputDateTimeInline extends React.Component {
  static propTypes = {
    isEditing: PropTypes.bool,
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    defaultValue: PropTypes.any.isRequired,
    dateFormat: PropTypes.oneOfType([
      PropTypes.any.isRequired,
      PropTypes.bool.isRequired,
    ]),
    timeFormat: PropTypes.string,
  }

  static defaultProps = {
    name: '',
    className: '',
    isEditing: true,
    dateFormat: 'MM/DD/YYYY',
    timeFormat: 'hh:mm a',
    onChange: () => {},
  };

  state = {
    isFocused: false,
  }

  render() {
    const {
      id,
      name,
      isEditing,
      defaultValue,
      onChange,
      dateFormat,
      timeFormat,
      className,
      onClick,
      ...othersProps
    } = this.props;
    const { isFocused } = this.state;

    if (!isEditing) {
      return <span className="datetime">{defaultValue.format(`${dateFormat} ${timeFormat}`)}</span>;
    }

    return (
      <span onClick={onClick}>
        <Datetime
          initialValue={defaultValue}
          className={classnames(className, { 'is-focused': isFocused })}
          dateFormat={dateFormat}
          timeFormat={timeFormat}
          onFocus={() => this.setState({ isFocused: true })}
          onBlur={() => this.setState({ isFocused: false })}
          onChange={value => onChange({ target: { id, name: name.length ? name : id, value } })}
          {...othersProps}
        />
      </span>
    );
  }
}
