import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SegmentTitle from './segment-title';

import 'styles/_segment.scss';

const Segment = ({ children, title, className }) => (
  <Fragment>
    {title && <SegmentTitle title={title} />}
    <div className={classNames({ [className]: className }, 'segment', 'segment-boxed')}>
      {children}
    </div>
  </Fragment>
);

Segment.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
};

Segment.defaultProps = {
  title: '',
  className: '',
};

export default Segment;
