import React from 'react';

class CSRFForm extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.submit = this.submit.bind(this);
  }

  onSubmit(e) {
    const csrfTokenInput = document.querySelector('input[name="csrfmiddlewaretoken"]');
    if (csrfTokenInput) {
      e.target.appendChild(csrfTokenInput.cloneNode());
    }
    if (this.props.onSubmit) {
      this.props.onSubmit(e, {
        csrfmiddlewaretoken: csrfTokenInput.value
      });
    }
  }

  submit() {
    this.form.dispatchEvent(new Event("submit"));
    this.form.submit();
  }

  render() {
    const { props } = this;
    return (
      <form
        ref={form => this.form = form}
        className={this.props.className}
        action={this.props.action}
        method={this.props.method || 'GET'}
        onSubmit={this.onSubmit}
      >
        {this.props.children}
      </form>
    );
  }
}

export default CSRFForm;
