export const SUCEEDED = 'SUCEEDED';
export const REQUESTED = 'REQUESTED';
export const FAILED = 'FAILED';
export const CLEAR_RESPONSE = 'CLEAR_RESPONSE';
export const CLEAR_MESSAGE = 'CLEAR_MESSAGE';
export const methods = {
  READ: 'read',
  CREATE: 'created',
  CREATED: 'created',
  UPDATE: 'update',
  UPDATED: 'updated',
  DELETE: 'delete',
  DELETING: 'deleting',
  DELETED: 'deleted',
  FAIL: 'fail',
};

export default function resourceActionsFactory(actions = {}) {
  const actionMap = {
    ...actions,
    [REQUESTED]: actions[REQUESTED] || REQUESTED,
    [FAILED]: actions[FAILED] || FAILED,
    [SUCEEDED]: actions[SUCEEDED] || SUCEEDED,
    [CLEAR_RESPONSE]: actions[CLEAR_RESPONSE] || CLEAR_RESPONSE,
    [CLEAR_MESSAGE]: actions[CLEAR_MESSAGE] || CLEAR_MESSAGE,
  };

  const dispatchers = {
    [REQUESTED](id, params = {}) {
      return {
        type: actionMap[REQUESTED],
        payload: {
          id,
          ...params,
        },
      };
    },
    [FAILED](error, id, params = {}) {
      return {
        type: actionMap[FAILED],
        payload: {
          id,
          error,
          ...params,
        },
      };
    },
    [SUCEEDED](response, id, params = {}) {
      return {
        type: actionMap[SUCEEDED],
        payload: {
          response,
          id,
          ...params,
        },
      };
    },
    [CLEAR_RESPONSE]() {
      return {
        type: actionMap[CLEAR_RESPONSE],
      };
    },
    [CLEAR_MESSAGE](params = {}) {
      return {
        type: actionMap[CLEAR_MESSAGE],
        payload: {
          ...params,
        },
      };
    },
  };

  return { dispatchers, actionMap };
}
