import { makeReduxAssets } from 'resource-toolkit';
import DynamicRoute from 'app/api/dynamic-routes';

const namespace = 'DYNAMIC_ROUTES';

export const dynamicRoutesResource = makeReduxAssets({
  name: namespace,
  idKey: 'id',
  expectAllMeta: true,
  gateway: {
    fetchMany: (dumb, { routeId }) => DynamicRoute.getByRoute(routeId),
    create: ({ routeId, ...creatingData }) => DynamicRoute.postByRoute(routeId, creatingData),
    update: (dynamicRouteId, { routeId, ...data }) => DynamicRoute.patchByRoute(routeId, dynamicRouteId, data),
    delete: (dynamicRouteId, routeId) => DynamicRoute.destroyByRoute(routeId, dynamicRouteId),
  },
});

export const initialState = dynamicRoutesResource.initialState;

export const dynamicRoutesActions = dynamicRoutesResource.actions;

export default dynamicRoutesResource.reducer;
