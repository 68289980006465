import React from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment-timezone';
import classNames from 'classnames';

export const HistoryItem = ({ children, className }) => (
  <span className={classNames('history-item', className)}>
    {children}
  </span>
);

class Table extends React.Component {
  static propTypes = {
    className: PropTypes.string,
  }

  static defaultProps = {
    className: '',
  }

  static dateFormatter(field) {
    if (field instanceof moment) {
      const date = field;
      return (
        <HistoryItem className="date">
          <span>
            {date.format('L')}
          </span>
          <span>
            {date.format('LTS')}
          </span>
        </HistoryItem>
      );
    }

    return <HistoryItem>{field}</HistoryItem>;
  }

  static columnFormatter(field, row, rowIndex, { history, dataField }) {
    if (!field) return '';
    history = history.filter(i => !i.isNote);
    rowIndex = history.indexOf(row);
    const keys = Array.isArray(dataField) ? dataField : [dataField];
    const prevItemIndex = Math.min(history.length - 1, rowIndex + 1);
    const prevItem = history[prevItemIndex];
    const isUnchanged = keys.every(key => (
      prevItem[key] === row[key]
    ));
    const classes = classNames({
      unchanged: isUnchanged && rowIndex,
      changed: !isUnchanged,
    });

    return (
      <HistoryItem className={classes}>
        {field}
      </HistoryItem>
    );
  }

  render() {
    const { keyField, columns, data, rowClasses, ...otherProps } = this.props;
    return (
      <div className={this.props.className || 'timeline-table'}>
        <BootstrapTable
          {...{ keyField, columns, data, rowClasses }}
          {...otherProps}
          noDataIndication={() => {
            return `${this.props.noDataMessage || 'No data to display'}`;
          }}
          hover
          bordered={false}
        />
      </div>
    );
  }
}
export default Table;
