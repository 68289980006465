import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Select } from 'components/segment/fields';

export const ALL = '';
export const APPROVED = 'Approved';
export const REJECTED = 'Rejected';
export const PENDING = 'Pending';
export const NOT_APPROVED = 'Not Approved';

const parseStatus = (cnam) => {
  const {
    isApproved,
    approvedOn,
    rejectedOn,
    rejectedReason,
  } = cnam;

  if (isApproved) {
    return {
      name: APPROVED,
      date: approvedOn,
    };
  }

  if (rejectedOn || rejectedReason) {
    return {
      name: REJECTED,
      date: rejectedOn,
    };
  }

  return {
    name: PENDING,
    date: null,
  };
};

const StatusSelector = (props) => {
  const {
    cnam,
    onSelect,
    disabled,
  } = props;

  const currentStatus = parseStatus(cnam);
  const changingPossibilities = currentStatus.name === PENDING
    ? [PENDING, APPROVED, REJECTED]
    : [APPROVED, REJECTED];

  return (
    <Select
      title={currentStatus.date && `${currentStatus.name} on ${currentStatus.date.format('ll')}`}
      value={currentStatus.name}
      disabled={disabled}
      onChange={proxy => onSelect(proxy.target.value)}
      options={changingPossibilities.map(option => ({ value: option, label: option }))}
    />
  );
};

StatusSelector.propTypes = {
  cnam: PropTypes.shape({
    isApproved: PropTypes.bool,
    approvedOn: PropTypes.instanceOf(moment),
    rejectedOn: PropTypes.instanceOf(moment),
    rejectedReason: PropTypes.string,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default StatusSelector;
