import Cookies from 'js-cookie';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import flow from 'lodash/flow';
import snakeCase from 'lodash/snakeCase';
import isEmpty from 'lodash/isEmpty';
import identity from 'lodash/identity';
import { parseResponse } from 'app/utils';

export function fetchWithCsrfToken(url, params, hasFile = false, headers = {}) {
  // Deprecated! Use `makeSystemClient` instead.
  const http_headers = new Headers({
    'X-CSRFToken': Cookies.get('csrftoken'),
    ...(!hasFile && { 'Content-Type': 'application/json' }),
    ...headers,
  });

  return fetch(url, { ...params, credentials: 'include', headers: http_headers });
}

function applyToObjectFn(formatKey, maxDepth = 3) {
  /*
    This wrapper was created to hide the 'depth' parameter from the user
    in order to avoid problems, since it controls when the recursion stops
  */
  const wrapper = (formatKey, maxDepth, depth) => (data = {}) => {
    if (depth < maxDepth) {
      if (typeof data === 'object' && data !== null) {
        if (isEmpty(data)) {
          return {};
        }

        return reduce(data, (acc, value, key) => {
          const newValue = typeof value === 'object' && value !== null
            ? Array.isArray(value)
              ? map(value, wrapper(formatKey, maxDepth, depth + 1))
              : wrapper(formatKey, maxDepth, depth + 1)(value)
            : value;

          acc[formatKey(key)] = newValue;

          return acc;
        }, {});
      }
    }

    return data;
  };

  return wrapper(formatKey, maxDepth, 0);
}

function stringifyQuery(data = {}) {
  if (isEmpty(data)) {
    return '';
  }

  return `?${map(data, (value, key) => `${key}=${value}`).join('&')}`;
}

function stringifyBody(data = {}) {
  if (isEmpty(data)) {
    return '{}';
  }

  return JSON.stringify(data);
}

function validateResourceEndpoint(endpoint) {
  if (!endpoint || typeof endpoint !== 'string') {
    throw new Error(`Required string as resource endpoint. Got "${endpoint}" ${typeof endpoint}.`);
  }

  if (endpoint.startsWith('/')) {
    throw new Error(`Should not start with a slash. Got: "${endpoint}"`);
  }

  if (endpoint.startsWith('http')) {
    throw new Error(`Should not start with 'http'. Got: "${endpoint}"`);
  }

  if (endpoint.endsWith('/')) {
    throw new Error(`Should not end with a slash. Got: "${endpoint}"`);
  }
}

function isRequired() {
  throw new Error('Missing required parameter on API client usage.');
}

const abcRequestInit = {
  headers: global.Headers ? new Headers({
    'X-CSRFToken': Cookies.get('csrftoken'),
    'Content-Type': 'application/json',
  }) : {},
  credentials: 'include',
};

/**
 * Factory of clients to consume our internal System API resources.
 *
 * The created object will have implemented get/post/patch/delete
 * async methods, with proper headers and authentication, so you'll
 * just need to pass some `id` or `data` for body or querystring.
 *
 * @param {string} endpoint base for the resource, without first slash, nor query,
 *                          nor "api" prefix (e.g.: "admins/" or simply "admins").
 * @param {object} options extra ways to extend the created client behavior.
 * @param {object.function} extraKeyFormat receives the final key and may return
 *                                         one somehow different. Identity function
 *                                         as default.
 */
export function makeSystemClient(endpoint, options = {}) {
  validateResourceEndpoint(endpoint);
  const {
    extraKeyFormat = identity,
  } = options;

  const baseUrl = `/${window.SYS_LOGIN_PATH}/api/${endpoint}/`;

  const queryChain = flow(
    applyToObjectFn(snakeCase),
    applyToObjectFn(extraKeyFormat),
    stringifyQuery,
  );

  const bodyChain = flow(
    applyToObjectFn(snakeCase),
    applyToObjectFn(extraKeyFormat),
    stringifyBody,
  );

  return {
    endpoint,
    baseUrl,
    get: (data = {}, extraInit = {}) => fetch(baseUrl + queryChain(data), {
      ...abcRequestInit,
      method: 'GET',
      ...extraInit,
    }).then(parseResponse),
    post: (data = isRequired(), extraInit = {}) => fetch(baseUrl, {
      ...abcRequestInit,
      method: 'POST',
      body: bodyChain(data),
      ...extraInit,
    }).then(parseResponse),
    patch: (id = isRequired(), data = isRequired(), extraInit = {}) => fetch(`${baseUrl}${id}/`, {
      ...abcRequestInit,
      method: 'PATCH',
      body: bodyChain(data),
      ...extraInit,
    }).then(parseResponse),
    put: (id = isRequired(), data = isRequired(), extraInit = {}) => fetch(`${baseUrl}${id}/`, {
      ...abcRequestInit,
      method: 'PUT',
      body: bodyChain(data),
      ...extraInit,
    }).then(parseResponse),
    delete: (id = isRequired(), data = {}, extraInit = {}) => fetch(`${baseUrl}${id}/`, {
      ...abcRequestInit,
      method: 'DELETE',
      body: bodyChain(data),
      ...extraInit,
    }).then(parseResponse),
  };
}
