import { parseResponse } from 'app/utils';
import { fetchWithCsrfToken } from 'app/api/fetch';

function getCustomerTransactions(customerId, { page, perPage }) {
  return fetchWithCsrfToken(
    `/${window.SYS_LOGIN_PATH}/api/customers/${customerId}/transactions?page=${page}&per_page=${perPage}`,
    { method: 'GET' },
  ).then(parseResponse);
}

function revertCustomerTransactions(customerId, transactions = []) {
  return fetchWithCsrfToken(
    `/${window.SYS_LOGIN_PATH}/api/customers/${customerId}/transactions/revert`,
    {
      method: 'PUT',
      credentials: 'include',
      body: JSON.stringify({ transactions }),
    },
  ).then(parseResponse);
}

export default {
  get: getCustomerTransactions,
  revert: revertCustomerTransactions,
};
