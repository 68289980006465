import pickBy from 'lodash/pickBy';
import set from 'lodash/set';
import mapKeys from 'lodash/mapKeys';
import capitalize from 'lodash/capitalize';
import resourceReducerFactory, { resourceState, pagination } from 'reducers/resource';
import {
  SUCEEDED, REQUESTED, FAILED
} from 'actions/resource';

import {
  ADD_NUMBER_FILTER,
  REMOVE_NUMBER_FILTER,
  CLEAR_RESPONSE,
  DID_CHANGE_FIELD,
  didActions,
  purchaseDidActions,
} from 'actions/did';


const filterNumberByOptions = [
  { value: 'starts_with', label: 'Starts with' },
  { value: 'contains', label: 'Contains' },
  { value: 'ends_with', label: 'Ends with' },
];

export const initialState = {
  isInitialState: true,
  isLoading: false,
  searchResponse: {
    ...resourceState, pagination,
    match: null
  },
  purchasedDids: [],
  purchaseResponse: null,
  values: {
    numbers: [
      {
        filterNumberBy: 'starts_with',
        number: '',
        options: [ ...filterNumberByOptions ]
      },
    ],
    state: '',
    rateCenter: '',
  },
}

export function getUpdatedMatchList(state) {
  const { numbers, ...match } = state.values;
  numbers.forEach(({ filterNumberBy, number }) => {
    const option = filterNumberByOptions.find(o => o.value === filterNumberBy);
    match[option.label] = number;
  });
  return mapKeys(pickBy(match), (_, key) => capitalize(key));
}

function updateNumberFieldsOptions(numbers) {
  numbers.forEach(field => {
    const setValues = numbers.map(({ filterNumberBy }) => filterNumberBy);
    const options = filterNumberByOptions.map(option => ({
      ...option,
      disabled: (setValues.includes(option.value) && option.value !== field.filterNumberBy)
    }));
    field.options = options;
  });

  return numbers;
}

const searchReducer = resourceReducerFactory(initialState.searchResponse, didActions);

function did(state = initialState, action) {
  switch(action.type) {
    case DID_CHANGE_FIELD:
      const { fieldName, fieldValue } = action;
      const { values } = state;
      set(values, fieldName, fieldValue);

      return {
        ...state,
        values: {
          ...values,
          numbers: updateNumberFieldsOptions(values.numbers)
        }
      };
    case ADD_NUMBER_FILTER: {
      let { numbers } = state.values;
      const newField = {number: ''};
      numbers.push(newField);
      numbers = updateNumberFieldsOptions(numbers);
      newField.filterNumberBy = newField.options.find(o => !o.disabled).value;
      return {
        ...state,
        values: {
          ...state.values,
          numbers: updateNumberFieldsOptions(numbers),
        }
      }
    }
    case REMOVE_NUMBER_FILTER: {
      const { numbers } = state.values;
      numbers.splice(action.index, 1);
      return {
        ...state,
        values: {
          ...state.values,
          numbers: updateNumberFieldsOptions(numbers),
        }
      }
    }
    case didActions[REQUESTED]:
      return {
        ...state,
        searchResponse: searchReducer(state.searchResponse, action),
        isInitialState: !state.searchResponse.data.length,
        isLoading: true
    }
    case didActions[SUCEEDED]:
      return {
        ...state,
        isLoading: false,
        isInitialState: false,
        searchResponse: {
          ...searchReducer(state.searchResponse, action),
          match: getUpdatedMatchList(state)
      }
      };
    case didActions[FAILED]:
      return {
        ...state,
        isInitialState: false,
        isLoading: false,
        searchResponse: searchReducer(state.searchResponse, action),
      };
    case purchaseDidActions[REQUESTED]:
      const didPurchasing = state.searchResponse.data.find(d => d.id === action.payload.id);
      didPurchasing.purchasing = true;
      return {
        ...state,
        isLoading: true,
        purchaseResponse: null,
        searchResponse: {
          ...state.searchResponse,
          data: state.searchResponse.data
        }
      }
    case purchaseDidActions[SUCEEDED]:
      const didToRemove = state.searchResponse.data.find(d => d.id === action.payload.id);
      didToRemove.purchased = true;
      didToRemove.purchasing = false;
      return {
        ...state,
        isLoading: false,
        purchaseResponse: {
          isError: false,
          id: action.payload.id,
          purchased: true,
        },
        searchResponse: {
          ...state.searchResponse,
          data: state.searchResponse.data,
        },
        purchasedDids: [...state.purchasedDids, action.payload.id],
      };
    case purchaseDidActions[FAILED]:
      const didFailed = state.searchResponse.data.find(d => d.id === action.payload.id);
      didFailed.purchasing = false;
      return {
        ...state,
        isLoading: false,
        purchaseResponse: {
          isError: true,
          message: action.payload.error.message,
          id: action.payload.id,
          purchased: false,
        },
        searchResponse: {
          ...state.searchResponse,
          data: state.searchResponse.data,
        },
      };
    case CLEAR_RESPONSE:
      return {
        ...state,
        purchaseResponse: null,
      }
    default:
      return state;
  }
}

export default did;
