import React from 'react';
import PropTypes from 'prop-types';
import { alertTypes } from 'app/components/alerts/alert';
import Toast from 'components/alerts/toast';

const getType = (type) => {
  switch (type) {
    case 'error':
    case 'danger':
      return alertTypes.DANGER;
    case 'success':
    case 'info':
    default:
      return alertTypes.INFO;
  }
};

const SimpleToast = ({
  message, type, onClose, ...othersProps
}) => (
  <Toast
    visible={!!message}
    message={message}
    type={getType(type)}
    onClose={onClose}
    {...othersProps}
  />
);


SimpleToast.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

SimpleToast.defaultProps = {
  message: '',
  type: 'info',
};

export default SimpleToast;
