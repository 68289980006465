import { makeReduxAssets, makeDefaultMessageText } from 'resource-toolkit';
import { ErrorStatus } from 'app/utils';
import * as CommonAPI from 'app/api';

const namespace = 'INBOUND_ROUTES';

export const inboundRoutesResource = makeReduxAssets({
  name: namespace,
  idKey: 'id',
  expectAllMeta: true,
  gateway: {
    fetchMany: (routeIds, query) => CommonAPI.getRoutes(query),
    update: async (routeId, routeData) => (await CommonAPI.patchRoute(routeId, routeData)).data,
    create: async routeData => (await CommonAPI.createRoute(routeData)).data[0],
    delete: async routeId => (await CommonAPI.deleteRoute(routeId)).data,
  },
  makeMessageText: (content, operation, error) => {
    if (error instanceof ErrorStatus && error.message) {
      return error.message;
    }

    if (operation === 'CREATE' && !error) {
      return `Successfully added "${content.value}". Apply it to some DIDs using the DID Management page.`;
    }

    return makeDefaultMessageText(content, operation, error);
  },
});

export const { initialState } = inboundRoutesResource;

export const inboundRoutesActions = inboundRoutesResource.actions;

export default inboundRoutesResource.reducer;
