import _ from 'lodash';
import * as types from 'app/redux/types';
import resourceReducerFactory, { resourceState } from 'reducers/resource';
import { typesDay } from 'actions/message-stats-actions';

export const initialState = resourceState;

export const dayMdrs = resourceReducerFactory(initialState, typesDay);

const initialDaySummaries = {
  summaries: {},
  isLoading: false,
  isError: false,
};

export function daySummaries(state = initialDaySummaries, action) {
  switch (action.type) {
    case types.ADD_DAY_SUMMARIES:
      const raw_summaries = action.summaries;
      const summariesObj = _.extend({}, state);
      let summaries = _.get(summariesObj, 'summaries', {});
      for (const index in raw_summaries) {
        const summary = raw_summaries[index].attributes;
        const date = summary.date;
        const timerange = date.split('-');
        if (timerange.length === 1) {
          // Skipping LIFETIME for now
          const yearcheck = parseInt(timerange[0]) || 0;
          if (yearcheck != 0) {
            const year = timerange[0];
            const obj = {};
            obj[year] = summary;
            summaries = _.merge(obj, summaries);
          }
        } else if (timerange.length === 2) {
          const year = timerange[0];


          const month = timerange[1];
          const obj = {};
          obj[year] = {};
          obj[year][month] = summary;
          summaries = _.merge(obj, summaries);
        } else if (timerange.length === 3) {
          const year = timerange[0];


          const month = timerange[1];


          const day = timerange[2];
          const obj = {};
          obj[year] = {};
          obj[year][month] = {};
          obj[year][month][day] = summary;
          summaries = _.merge(obj, summaries);
        }
      }
      return {
        summaries,
        isError: false,
        isLoading: false,
      };
    case types.DAY_SUMMARIES_REQUEST:
      return {
        ...initialDaySummaries,
        isLoading: true,
      };
    case types.DAY_SUMMARIES_FAILED:
      return {
        ...initialDaySummaries,
        isError: true,
      };
    default:
      return state;
  }
}
