import React from 'react';
import PropTypes from 'prop-types';
import Toast from './toast';

const ResourceToast = ({ currentMessage, clear }) => {
  if (!currentMessage) {
    return null;
  }

  const { text, isError } = currentMessage;

  return (
    <Toast
      message={text}
      type={isError ? 'danger' : 'info'}
      onClose={clear}
      visible
    />
  );
};

ResourceToast.propTypes = {
  currentMessage: PropTypes.shape({
    text: PropTypes.string.isRequired,
    isError: PropTypes.bool.isRequired,
  }),
  clear: PropTypes.func.isRequired,
};

ResourceToast.defaultProps = {
  currentMessage: null,
};

export default ResourceToast;
