import customerTransactions from 'app/api/customer-transactions';
import resourceActionsFactory, {
  REQUESTED,
  FAILED,
  SUCEEDED,
  CLEAR_MESSAGE,
  methods,
} from 'actions/resource';

export const { dispatchers, actionMap: actions } = resourceActionsFactory({
  [REQUESTED]: 'CUSTOMER_TRANSACTIONS/REQUESTED',
  [FAILED]: 'CUSTOMER_TRANSACTIONS/FAILED',
  [SUCEEDED]: 'CUSTOMER_TRANSACTIONS/SUCEEDED',
  [CLEAR_MESSAGE]: 'CUSTOMER_TRANSACTIONS/CLEAR_MESSAGE',
});

export const { dispatchers: reversalDispatchers, actionMap: reversalActions } = resourceActionsFactory({
  [REQUESTED]: 'CUSTOMER_TRANSACTIONS/REVERSAL/REQUESTED',
  [FAILED]: 'CUSTOMER_TRANSACTIONS/REVERSAL/FAILED',
  [SUCEEDED]: 'CUSTOMER_TRANSACTIONS/REVERSAL/SUCEEDED',
  [CLEAR_MESSAGE]: actions[CLEAR_MESSAGE],
  CHECK: 'CUSTOMER_TRANSACTIONS/REVERSAL/CHECK',
});

export const clearMessage = dispatchers[CLEAR_MESSAGE];

export function requestCustomerTransactions(customer, query) {
  return async (dispatch) => {
    dispatch(dispatchers[REQUESTED]());
    try {
      const { data, meta: pagination } = await customerTransactions.get(customer, query);
      return dispatch(dispatchers[SUCEEDED](
        { data, pagination: { ...query, ...pagination } },
        customer,
        { method: methods.READ },
      ));
    } catch (e) {
      return dispatch(dispatchers[FAILED](e));
    }
  };
}

export function revertCustomerTransactions(customer, transactions) {
  return async (dispatch) => {
    dispatch(reversalDispatchers[REQUESTED](transactions));
    try {
      const { message } = await customerTransactions.revert(customer, transactions);
      return dispatch(reversalDispatchers[SUCEEDED](
        { message }, transactions, { method: methods.UPDATED },
      ));
    } catch (e) {
      return dispatch(reversalDispatchers[FAILED](e, transactions));
    }
  };
}

export const checkTransaction = (id, e = {}) => ({
  type: reversalActions.CHECK,
  payload: { id, massCheck: e.shiftKey },
});
