import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ReactSelect from 'components/select';
import head from 'lodash/head';
import HelpTooltip from 'components/help-tooltip';
import SubmitButton from 'components/submit-button';
import { InputDateTimeInline as InputDateTimeInlineInput } from 'components/input-inline';

import './label.scss';


const CloseButton = ({ onClick, label }) => (
  <div className="group-fields__button-close">
    <button type="button" className="btn-close" onClick={onClick} aria-label={`Remove ${label}`}>
      <i className="fa fa-times" />
    </button>
  </div>
);

function RenderTooltip({ name, tooltip, tooltipPosition }) {
  if (tooltip) {
    const tooltipPropsBase = {
      id: name,
      tooltip,
    }

    const tooltipProps = tooltipPosition
      ? {...tooltipPropsBase, place: tooltipPosition}
      : tooltipPropsBase;

    return (
      <HelpTooltip {...tooltipProps} />
    );
  }
  return null;
}

RenderTooltip.propTypes = {
  name: PropTypes.string,
  tooltip: PropTypes.any,
};

RenderTooltip.defaultProps = {
  name: '',
  tooltip: null,
};


function renderTooltip(id, tooltip) {
  if (tooltip) {
    return (
      <HelpTooltip {...{ id, tooltip }} />
    );
  }
}


/**
 * To add form-group with label, passing the children
 *
 * @param {any} {
 *   label, name, tooltip, isClose, className, children, ...props
 * }
 */
export const Label = ({
  label, name, tooltip, isClose, className, children, tooltipPosition, ...props
}) => (
  <div className={classnames('form-group form-group-wide group-fields', className)} data-secondary={props.secondary}>
    {label && (
      <label htmlFor={name}>
        {label}
        <RenderTooltip name={`tooltip-${name}`} tooltip={tooltip} tooltipPosition={tooltipPosition} />
      </label>
    )}
    {isClose && (
      <CloseButton onClick={props.onClose} label={label}/>
    )}
    {children}
  </div>
);

Label.propTypes = {
  label: PropTypes.string,
  // eslint-disable-next-line consistent-return
  name(props, propName) {
    if (props.label.length && !props[propName].length) {
      return new Error('Please provide a name function!');
    }
  },
  className: PropTypes.any,
  tooltip: PropTypes.string,
  children: PropTypes.any,
};

Label.defaultProps = {
  className: {},
  label: '',
  name: '',
  tooltip: '',
  children: null,
};

/**
 * Using input inside of form-group and label
 */
export const Input = ({ className, isClose, ...props }) => (
  <Label {...{ className, isClose }} {...props}>
    <input
      className="form-control margin-right-sm"
      aria-label={props.label}
      {...props}
    />
  </Label>
);

Input.propTypes = {
  className: PropTypes.string,
  isClose: PropTypes.bool,
};

Input.defaultProps = {
  className: '',
  isClose: false,
};


/**
 * This component create a input group with date-time picker
 */
export const InputDateTimeInline = ({
  id,
  name,
  label,
  tooltip,
  tooltipPosition,
  defaultValue,
  placeholder,
  labelProps,
  inputProps,
  ...props
}) => (
  <Label label={label} name={id} tooltip={tooltip} tooltipPosition={tooltipPosition} {...labelProps}>
    <InputDateTimeInlineInput
      defaultValue={defaultValue}
      id={id}
      name={name}
      inputProps={{
        id,
        name,
        placeholder,
        ...inputProps,
      }}
      {...props}
    />
  </Label>
);

InputDateTimeInline.propTypes = {
  /** id to use on `<input />`. */
  id: PropTypes.string.isRequired,
  /** name to use on `<input />`. */
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  /** defaultValue to use on value on `<input />`. */
  defaultValue: PropTypes.any,
  placeholder: PropTypes.string,
  tooltip: PropTypes.string,
  labelProps: PropTypes.object,
};

InputDateTimeInline.defaultProps = {
  defaultValue: '',
  placeholder: '',
  tooltip: '',
  labelProps: {},
  inputProps: {},
};

export class Phone extends React.Component {
  _onInput = () => {
    this.props.onInput({
      target: {
        value: {
          countryCode: this.countryCode,
          number: this.number,
        },
      },
    });
  }

  get countryCode() {
    return this._countryCodeInput.value;
  }

  get number() {
    return this._numberInput.value;
  }

  render() {
    const {
      value: { countryCode, number },
      name = 'country-code',
      ...props
    } = this.props;

    return (
      <div className="form-group form-group-wide">
        <label>
          {props.label}
          {renderTooltip(name, props.tooltip)}
        </label>
        <div className="phone-group">
          <div className="country-prefix">
            <input
              className="form-control margin-right-sm"
              ref={countryCodeInput => this._countryCodeInput = countryCodeInput}
              defaultValue={countryCode || 1}
              onInput={this._onInput}
            />
            <label className="secondary--below">Country</label>
          </div>
          <div className="phone">
            <input
              className="form-control phone margin-right-sm"
              ref={numberInput => this._numberInput = numberInput}
              defaultValue={number}
              onInput={this._onInput}
            />
            <label className="secondary--below">Telephone Number</label>
          </div>
        </div>
      </div>
    );
  }
}


const DefaultOption = ({ defaultOption }) => {
  if (!defaultOption) return null;
  return (
    <option value="">{defaultOption}</option>
  );
};

DefaultOption.propTypes = {
  defaultOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

DefaultOption.defaultProps = {
  defaultOption: '',
};

const SelectOptions = ({ options }) => options.map(({
  value, label, disabled, type, ...props
}) => {
  if (type === 'group') {
    return (
      <optgroup key={label} label={label}>
        <SelectOptions {...props} />
      </optgroup>
    );
  }

  return <option key={value} disabled={disabled} value={value}>{label}</option>;
});

/**
 * Component to add a select with options
 *
 * @param {any} {
 *   className, options, defaultOption, isClose, secondary, ...props
 * }
 */
export const Select = ({
  className, options, defaultOption, isClose, secondary, ...props
}) => (
  <Label {...{ className, isClose }} {...props}>
    <div className="select-container fa">
      <select
        className="form-control margin-right-sm"
        {...props}
      >
        <DefaultOption defaultOption={defaultOption} />
        <SelectOptions options={options} />
      </select>
    </div>
  </Label>
);

Select.propTypes = {
  className: PropTypes.string,
  isClose: PropTypes.bool,
  secondary: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    type: PropTypes.string,
    options: PropTypes.array,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  })),
};

Select.defaultProps = {
  className: '',
  isClose: false,
  secondary: false,
  options: [],
};

export const SelectSearch = ({
  className, options, isClose, ...props
}) => (
  <Label {...{ className, isClose }} {...props}>
    <ReactSelect
      options={options}
      {...props}
    />
  </Label>
);

export const Range = ({ from, to, ...props }) => (
  <div className="form-group form-group-wide range-group">
    <label>
      {props.label}
      {renderTooltip(props.name, props.tooltip)}
    </label>
    <div className="one-line">
      <div className="input-wrapper--currency">
        <input
          name={from.name}
          value={from.value}
          type="number"
          min="0"
          onChange={from.onChange}
          placeholder={props.placeholder}
        />
      </div>
      <label className="label" data-secondary>
        to
      </label>
      <div className="input-wrapper--currency">
        <input
          data-currency
          name={to.name}
          value={to.value}
          type="number"
          min="0"
          onChange={to.onChange}
          placeholder={props.placeholder}
        />
      </div>
    </div>
  </div>
);

export const Checkbox = ({
  name, label, checked, onChange, ...props
}) => (
  <div className="form-group form-group-wide checkbox-group">
    <input
      className="form-control margin-right-sm"
      type="checkbox"
      checked={checked}
      name={name}
      id={name}
      onChange={onChange}
      {...props}
    />
    <label htmlFor={name}>{label}</label>
  </div>
);


export const Submit = props => (
  <div className="form-group form-group-wide">
    <SubmitButton {...props} />
  </div>
);

// eslint-disable-next-line react/no-multi-comp
export class SelectList extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
      }),
    ).isRequired,
    optional: PropTypes.bool,
  };

  static defaultProps = {
    optional: false,
  };

  state = {
    fields: {},
  };

  componentDidMount() {
    if (!this.props.optional && Object.keys(this.state.fields).length === 0) {
      this.add();
    }
  }

  static getNextOption(options, fields) {
    return head(
      options.filter(
        option => !Object.entries(fields).find(field => field[1] === option.value),
      ),
    );
  }

  add = () => {
    const { fields } = this.state;
    const id = new Date().getTime();

    const newOption = SelectList.getNextOption(this.props.options, fields);

    const newFields = {
      ...fields,
      [id]: newOption.value,
    };
    this.setState({
      fields: newFields,
    });

    this.props.onChange({
      target: { name: this.props.name, value: Object.values(newFields) },
    });
  };

  remove = id => () => {
    const { fields } = this.state;

    delete fields[id];

    this.setState({ fields });

    this.props.onChange({
      target: { name: this.props.name, value: Object.values(fields) },
    });
  };

  handleInput = ({ target }) => {
    const { fields } = this.state;

    const id = target.name.match(/\[(.*?)\]/)[1];
    const newFields = {
      ...fields,
      [id]: target.value,
    };
    this.setState({
      fields: newFields,
    });

    this.props.onChange({
      target: { name: this.props.name, value: Object.values(newFields) },
    });
  };

  render() {
    const fields = Object.entries(this.state.fields).map(([key, value]) => ({
      name: key,
      value,
      options: this.props.options.map(option => ({
        ...option,
        disabled: !!Object.values(this.state.fields).find(
          item => item === option.value && option.value !== value,
        ),
      })),
    }));

    const {
      name, label, optional, ...props
    } = this.props;

    return (
      <React.Fragment>
        {fields.map((field, index) => (
          <React.Fragment key={field.name}>
            <Select
              name={`${name}[${field.name}]`}
              label={label}
              {...props}
              value={field.value}
              options={field.options}
              onChange={this.handleInput}
              onClose={this.remove(field.name)}
              isClose={(optional || index !== 0)}
            />
          </React.Fragment>
        ))}
        {fields.length < this.props.options.length && (
          <button type="button" className="btn-link btn-add" onClick={this.add}>
            Add {label}
          </button>
        )}
      </React.Fragment>
    );
  }
}
