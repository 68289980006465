import resourceReducerFactory, { resourceState } from 'reducers/resource';
import { actions as didNotesActions } from 'actions/did-notes';
import { actions } from 'actions/did-history';
import { mergeNotesIntoHistory } from 'app/utils';

export const initialState = {
  ...resourceState,
  data: [],
  notes: { ...resourceState, data: [] }
};
const historyReducer = resourceReducerFactory(initialState, actions);
const didNotesReducer = resourceReducerFactory(initialState.notes, didNotesActions);

export default function didHistoryReducer(state=initialState, action) {
  switch (action.type) {
    case didNotesActions.CLEAR_RESPONSE:
      return {
        ...state,
        notes: {
          ...state.notes,
          message: null,
        }
      }
    case didNotesActions.FAILED:
      if (state.notes.isInitialState) {
        return {
          ...state,
          notes: {
            ...state.notes,
            error: action.payload.error,
            isError: true,
            isLoading: false,
            message: 'Something went wrong while loading notes for this DID. try reloading the page'
          }
        }
      }
  }

  const newState = {
    ...historyReducer(state, action),
    notes: didNotesReducer(state.notes, action),
  };

  return {
    ...newState, history: mergeNotesIntoHistory(newState.notes.data, newState.data)
  };
}
