import Profiles from 'app/api/profiles';
import resourceActionsFactory, {
  REQUESTED,
  FAILED,
  SUCEEDED as SUCCEEDED,
  CLEAR_MESSAGE,
  methods,
} from 'actions/resource';


export const { dispatchers: action, actionMap: types } = resourceActionsFactory({
  [REQUESTED]: 'PROFILES/REQUESTED',
  [FAILED]: 'PROFILES/FAILED',
  [SUCCEEDED]: 'PROFILES/SUCCEEDED',
  [CLEAR_MESSAGE]: 'CLEAR_DID_NOTES_MESSAGE',
});

const requested = action[REQUESTED];
const succeeded = action[SUCCEEDED];

const failed = action[FAILED];

export const clearMessage = action[CLEAR_MESSAGE];

export function setProfile(customerId) {
  return (dispatch) => {
    dispatch(requested(customerId, { method: methods.UPDATE, message: '' }));

    return Profiles.set(customerId)
      .catch((error) => {
        let message = error.message.replace('Error 403', 'You do not have permission to view this profile.');
        message = message.replace('Error 404', 'Error with Selecting a Profile.  If this persists, please contact support@flowroute.com.');
        dispatch(failed(
          { message },
          customerId,
          { isLoading: false },
        ));
        throw error;
      });
  };
}

export function getProfiles() {
  return (dispatch) => {
    dispatch(requested());

    return Profiles.get()
      .then(response => dispatch(succeeded(response)))
      .catch(error => dispatch(failed(error)));
  };
}
