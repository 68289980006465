import {
  parametersFormatter,
  parseMultQueryParam,
} from 'app/utils';
import { api } from './index';

function searchPurchasablePhoneNumbers({
  credentials, ...query
}) {
  const qsParams = parametersFormatter(...parseMultQueryParam('order_by', query));

  return api(`/v2/numbers/available?${qsParams}`, {
    credentials,
    body: null,
    getMeta: true,
  });
}


export default {
  available: searchPurchasablePhoneNumbers,
};
