import {
  parseResponse,
  parseBody,
  parseDatetimeValues,
} from 'app/utils';
import { fetchWithCsrfToken } from 'app/api/fetch';

function getCustomerNotes(customerId, noteId = '') {
  return fetchWithCsrfToken(`/${window.SYS_LOGIN_PATH}/api/customers/${customerId}/notes/${noteId}`, {
    method: 'GET', credentials: 'include',
  }).then(parseResponse)
    .then(parseDatetimeValues);
}

function createCustomerNotes(customerId, data) {
  return fetchWithCsrfToken(`/${window.SYS_LOGIN_PATH}/api/customers/${customerId}/notes`, {
    method: 'POST', credentials: 'include', body: parseBody(data),
  }).then(parseResponse)
    .then(parseDatetimeValues);
}

function editCustomerNotes(customerId, noteId, data) {
  return fetchWithCsrfToken(`/${window.SYS_LOGIN_PATH}/api/customers/${customerId}/notes/${noteId}`, {
    method: 'PUT', credentials: 'include', body: parseBody(data),
  }).then(parseResponse)
    .then(parseDatetimeValues);
}

export default {
  get: getCustomerNotes,
  create: createCustomerNotes,
  edit: editCustomerNotes,
};
