import React, { Fragment } from 'react';
import 'styles/_submit-button.scss';

const formStates = {
  NOT_READY: 'notReady',
  READY: 'ready',
  SENDING: 'sending'
};

function SubmitButton ( {formState=formStates.NOT_READY, className='submit-button btn btn-medium btn-success', text, ...props} ) {
  const content = {
    [formStates.SENDING]: (
      <Fragment>
        <i className="fa fa-spinner fa-spin"></i>
        Processing...
      </Fragment>
    )
  };

  const defaultContent = (text || props.children);
  return (
    <button
      { ...props }
      disabled={formState !== formStates.READY}
      type="submit"
      className={`${className}`}
    >
      {content[formState] || defaultContent}
    </button>
  )
}

export {
  formStates
};

export default SubmitButton;
