import React, { Fragment } from 'react';
import { alertTypes } from 'app/components/alerts/alert';
import { formErrorMessage } from 'app/components/alerts/request-error';
import Toast from 'app/components/alerts/toast';

const getDefaultMessage = () => '';

function RequestResponseToast({ response, onClose, getMessage = getDefaultMessage }) {
  let message;
  let type;
  if (response) {
    if (response.isError) {
      type = alertTypes.DANGER;
      message = formErrorMessage(response.message);
    } else {
      message = (
        <Fragment>
          {getMessage(response)}
        </Fragment>
      )
    }
  }
  return (
    <Toast
      visible={Boolean(response)}
      message={message}
      type={type}
      onClose={onClose}
    />
  )
}

export default RequestResponseToast;
