import {
  parseDatetimeValues,
  parseResponse,
  addQueryString,
} from 'app/utils';
import { fetchWithCsrfToken } from 'app/api/fetch';

const fields = ['page', 'perPage'];

export function didNotes(did, query = {}) {
  const url = `/${window.SYS_LOGIN_PATH}/api/dids/${did}/notes`;

  return fetchWithCsrfToken(addQueryString(url, fields, query), {
    method: 'GET',
    credentials: 'include',
  })
    .then(parseResponse)
    .then(parseDatetimeValues);
}

export function createDidNote(did, note) {
  return fetchWithCsrfToken(`/${window.SYS_LOGIN_PATH}/api/dids/${did}/notes/`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({ note }),
  })
    .then(parseResponse)
    .then(parseDatetimeValues);
}

export function updateDidNote(did, noteId, note) {
  return fetchWithCsrfToken(`/${window.SYS_LOGIN_PATH}/api/dids/${did}/notes/${noteId}/`, {
    method: 'PUT',
    credentials: 'include',
    body: JSON.stringify({ note }),
  })
    .then(parseResponse)
    .then(parseDatetimeValues);
}

export default {
  get: didNotes,
  create: createDidNote,
  edit: updateDidNote,
};
