import * as A from 'actions/credentials-actions';

export default function credentials(state = {}, action) {
  switch (action.type) {
    case A.SET_CREDENTIALS:
      return action.credentials;
    case A.REQUESTED_CREDENTIALS:
      return { requested: action.timestamp };
    case A.FAILED_CREDENTIALS:
      return { error: action.error };
    default:
      return state;
  }
}
