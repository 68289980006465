import { fetchWithCsrfToken } from 'app/api/fetch';
import { parseResponse, parseBody, parseDataToSelectOptions } from 'app/utils';

function getProfiles() {
  return fetchWithCsrfToken(
    '/api/profiles',
    { method: 'GET', credentials: 'include' },
  )
    .then(parseResponse)
    .then(({ data, selectedProfile }) => Promise.resolve({
      data: parseDataToSelectOptions(
        {
          labelKey: 'label',
          valueKey: 'accounts',
        },
        data,
      ),
      selectedProfile,
    }));
}

function setProfile(customerId) {
  return fetchWithCsrfToken(
    `/accounts/selector/${customerId}/`, {
      method: 'POST',
      credentials: 'include',
      body: '',
    },
  )
    .then(parseResponse)
    .catch(e => console.error(e));
}

export default {
  get: getProfiles,
  set: setProfile,
};
