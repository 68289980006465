import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import ReactSelectAsync from 'react-select/async';
import isUndefined from 'lodash/isUndefined';

import './select.scss';

const Select = ({
  onChange,
  options,
  value,
  id,
  clearable,
  disabled,
  ...otherProps
}) => {
  const dynamicProps = {
    isLoading: disabled,
    isDisabled: disabled,
    styles: {
      singleValue: provided => ({
        ...provided,
        color: 'inherit',
      }),
    },
  };
  if (id) {
    dynamicProps.inputId = id;
  }

  dynamicProps.isClearable = !isUndefined(clearable) ? clearable : true;

  if (otherProps.onInputChange) {
    throw new Error(
      'Please use `loadOptions` props instead of `onInputChange`.',
    );
  }

  dynamicProps.onChange = (option) => {
    onChange({
      target: {
        id,
        name: otherProps.name,
        value: option !== null ? option.value : '',
      },
    });
  };

  const searchValue = options.filter(option => option.value === value);
  /* dontUseSearchvalue is a 'hacky' fix for the select not being able to change
   its value in the filter and exclude by country fields from the cdr exports filters
   if the 'value' field from dynamicProps is set */
  if (searchValue && !otherProps.dontUseSearchValue) {
    dynamicProps.value = searchValue;
  }

  if (otherProps.loadOptions) {
    return (
      <ReactSelectAsync
        className="react-select__container"
        classNamePrefix="react-select"
        defaultOptions
        loadOptions={otherProps.loadOptions}
        {...dynamicProps}
        {...otherProps}
      />
    );
  }

  return (
    <ReactSelect
      className="react-select__container"
      classNamePrefix="react-select"
      options={options}
      {...otherProps}
      {...dynamicProps}
    />
  );
};

Select.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  loadOptions: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      type: PropTypes.string,
      options: PropTypes.array,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
  ),
};

Select.defaultProps = {
  id: '',
  value: '',
  options: [],
  loadOptions: null,
};

export default Select;
