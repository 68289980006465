import React from 'react';
import PropTypes from 'prop-types';
import numericOrNullProp from 'app/types/number-or-null-prop';

import './table-header.scss';

const TableHeader = ({
  title, totalItems, isLoading, children,
}) => (
  <div className="table-header">
    <div className="table-header__title">{title}</div>
    {totalItems !== null && (
      <div className="table-header__count-items">
        {totalItems} Item{totalItems !== 1 && 's'}
      </div>
    )}
    {isLoading && (
      <div className="table-header__is-loading">
        <i className="fa fa-spin fa-spinner" />
      </div>
    )}
    <div className="table-header__items">
      {children}
    </div>
  </div>
);

TableHeader.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  isLoading: PropTypes.bool.isRequired,
  totalItems: numericOrNullProp,
};

TableHeader.defaultProps = {
  totalItems: null,
};

export const Filter = ({ label, id, children }) => (
  <div className="table-header__filter">
    <label htmlFor={id}>{label}</label>
    {children}
  </div>
);

Filter.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};


export default TableHeader;
