import {
  parseDatetimeValues,
  parseResponse,
  parseQuery,
} from 'app/utils';
import { fetchWithCsrfToken } from 'app/api/fetch';

export function getCustomers(querySet = {}, fetchOptions = {}) {
  const url = `/${window.SYS_LOGIN_PATH}/api/customers?${parseQuery(querySet)}`;

  return fetchWithCsrfToken(url, {
    method: 'GET',
    credentials: 'include',
    ...fetchOptions,
  }).then(parseResponse).then(parseDatetimeValues);
}

const sendCheckEmail = () => fetchWithCsrfToken('/signup/verify_email', {
  method: 'GET', credentials: 'include',
});

export default {
  getAll: getCustomers,
  sendCheckEmail,
};
