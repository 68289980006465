import React from 'react';
import PropTypes from 'prop-types';

import 'styles/_segment.scss';

const SegmentTitle = ({ children, title }) => (
  <div className="segment-title">
    <h2 className="segment-boxed">{children || title}</h2>
  </div>
);

SegmentTitle.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
};

SegmentTitle.defaultProps = {
  children: null,
  title: '',
};

export default SegmentTitle;
