import { parseDatetimeValues, parseDataToFlat, prepareBody } from "app/utils";
import { api } from "./index";
import qs from "qs";
import omit from "lodash/omit";

const getCDRs = async ({ query, credentials }) => {
  const querystringFromAPI = query.url ? query.url.split("?")[1] : "";

  const queryString = qs.stringify(prepareBody(omit(query, "url")), {
    indices: false,
  });

  const response = await api(`/v2/cdrs?${querystringFromAPI || queryString}`, {
    credentials,
    getMeta: true,
  });

  const { data, ...others } = await parseDatetimeValues(response);
  return { data: parseDataToFlat(data), ...others };
};

export const createExport = (parameters) => {
  const queryString = qs.stringify(
    prepareBody({ ...parameters, createExport: true })
  );
  window.location.assign(`/accounts/cdrs/exportsv2?${queryString}`);
};

export default {
  get: getCDRs,
};
