import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './progress-bar.scss';

const ProgressBar = ({ percentage, isAnimated }) => (
  <div
    className="progress"
  >
    <div
      className={classnames('progress-bar', {
        'progress-bar-striped active': isAnimated,
      })}
      role="progressbar"
      aria-valuenow={percentage}
      aria-valuemin="0"
      aria-valuemax="100"
      style={{ width: `${percentage}%` }}
    >
      <span>{percentage}%</span>
    </div>
  </div>
);

ProgressBar.propTypes = {
  percentage: PropTypes.number.isRequired,
  isAnimated: PropTypes.bool,
};

ProgressBar.defaultProps = {
  isAnimated: false,
};

export default ProgressBar;
