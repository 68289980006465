import compose from '@ramda/compose';
import slice from '@ramda/slice';
import max from 'lodash/max';
import min from 'lodash/min';
import get from 'lodash/get';
import chunk from 'lodash/chunk';
import capitalize from 'lodash/capitalize';
import curry from 'lodash/curry';
import has from 'lodash/has';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import isNumber from 'lodash/isNumber';
import defaultTo from 'lodash/defaultTo';
import omit from 'lodash/omit';
import upperFirst from 'lodash/upperFirst';
import isString from 'lodash/isString';
import moment from 'moment-timezone';
import qs from 'qs';

export function ErrorStatus(code, msg) {
  this.code = code;
  this.message = `Error${msg ? `: ${msg}` : ''}`;
}
ErrorStatus.prototype = Object.create(Error.prototype);


export const formatUserName = userInfo => (userInfo.firstName || userInfo.lastName
  ? `${userInfo.firstName} ${userInfo.lastName}`
  : userInfo.username);

export function formatCurrency(balance, fixedTo = 2) {
  const number = Number(balance);

  if (number < 0) {
    return `-$${Math.abs(number).toFixed(fixedTo)}`;
  }

  return `$${number.toFixed(fixedTo)}`;
}

export function queryParams(paramsMap) {
  if (!paramsMap) return '';

  const queriesString = Object.entries(paramsMap).map(
    ([key, value]) => `${key}=${value}`,
  ).join('&');

  return `?${queriesString}`;
}

export function loadTexts(texts) {
  return Object.keys(texts).reduce(
    (obj, key) => {
      obj[key] = [].concat(texts[key]);
      return obj;
    },
    {},
  );
}

export function onFieldChange(dispatchAction, name) {
  return (e) => { dispatchAction(name, e.target.value); };
}


export function compareTimestamp(a, b) {
  if (new Date(a.timestamp) < new Date(b.timestamp)) { return -1; }
  if (new Date(a.timestamp) > new Date(b.timestamp)) { return 1; }
  return 0;
}

export function paginateList(page, perPage = 25, list, sort) {
  if (!list.length) return [];

  const offset = (page * perPage) - perPage;

  if (sort) {
    return list
      .sort(sort)
      .slice(offset, offset + perPage);
  }

  return list.slice(offset, offset + perPage);
}

export const parseJSON = (value, defaultValue = null) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    if (defaultValue) {
      return defaultValue;
    }
    return value;
  }
};

export const creatorCaseToLowerCamelCase = separator => value => value.split(separator)
  .map((word, indexWord) => {
    if (indexWord === 0) return word;

    return word.split('').map((letter, indexLetter) => {
      if (indexLetter !== 0) return letter;

      return letter.toUpperCase();
    }).join('');
  }).join('');


export function snakeCaseToLowerCamelCaseResponse(response) {
  return new Promise((resolve) => {
    response.text().then((text) => {
      const replaced = text.replace(/"\w*_\w*"\s*:/g, (match) => {
        const [head, ...tail] = match.split('_');
        return [head, ...tail.map(upperFirst)].join('');
      });
      resolve(parseJSON(replaced, text));
    });
  });
}

const camelCaseConvertFactor = some => value => (
  value.replace(/[A-Z]/g, match => `${some}${match.toLowerCase()}`)
);

export const camelCaseToLowerSnakeCase = camelCaseConvertFactor('_');

export const camelCaseToLowerSeparated = camelCaseConvertFactor(' ');

export const parseIt = (parse, item) => (
  isArray(item) ? item.map(parse) : parse(item)
);

/**
 * Parse ID to number if is a number or just return the value
 *
 * TODO: Put this file on utils.
 *
 * @param {string} idValue
 * @returns {number|string}
 */
export const parseId = idValue => (
  isNumber(idValue) ? parseInt(idValue, 10) : idValue
);

export const snakeCaseToLowerCamelCase = creatorCaseToLowerCamelCase('_');

export const kebabCaseToLowerCamelCase = creatorCaseToLowerCamelCase('-');

// this function appears to be changing keys from {'customer_id': 2} to {'customerId': 2}
// Why?  That causes errors that are time consuming to debug if your api has keys with underscores.
// Why can't this function be agnostic to the spelling convention used in the keys in the response?
export function parseResponse(response) {
  const formatter = snakeCaseToLowerCamelCaseResponse(response);
  const hasAuthExpired = response.url.includes('/accounts/login/?next=') || response.url.includes(`/${window.SYS_LOGIN_PATH}/login/?next=`);
  if (hasAuthExpired) {
    throw new ErrorStatus(
      403,
      'User authentication expired. Please reload the page to login again',
    );
  }
  if (!String(response.status).startsWith('2') || !response.ok) {
    return formatter.then((data) => {
      let errors;
      if (data.errors) {
        if (isArray(data.errors)) {
          errors = data.errors;
        } else {
          errors = Object.keys(data.errors).map(error => `${error}: ${data.errors[error]}`);
        }
      } else {
        errors = [data.message];
      }
      errors = errors.map(e => typeof e === 'object' && (e.detail || e.title) || e);
      if (typeof errors[0] !== 'string' && typeof errors[0] !== 'undefined') {
        errors = errors
          .map((error) => {
            const parsedError = [];
            if (error.data && error.data.attributes) {
              if (error.data.attributes.filterParameters) {
                parsedError.push(Object.keys(error.data.attributes.filterParameters)
                  .map((key) => {
                    const keyError = error.data.attributes.filterParameters[key];
                    const newKey = capitalize(camelCaseToLowerSeparated(key));
                    const value = typeof keyError === 'string' ? keyError : keyError[0];
                    return `${newKey}:"${value}"`;
                  }));
                delete error.data.attributes.filterParameters;
              }

              if (error.data && error.data.attributes) {
                parsedError.push(Object.keys(error.data.attributes)
                  .map((key) => {
                    const keyError = error.data.attributes[key];
                    const newKey = capitalize(camelCaseToLowerSeparated(key));
                    const value = typeof keyError === 'string' ? keyError : keyError[0];
                    return `${newKey}: "${value}"`;
                  }));
              }
            }

            if (error.query) {
              parsedError.push(Object.keys(error.query)
                .map((key) => {
                  const keyError = error.query[key];
                  const newKey = capitalize(camelCaseToLowerSeparated(key));
                  const value = typeof keyError === 'string' ? keyError : keyError[0];
                  return `${newKey}: "${value}"`;
                }));
            }

            return parsedError;
          });
      }

      errors = errors.join(', ');
      throw new ErrorStatus(response.status, errors);
    }, (data) => {
      const errors = data.errors.map(e => typeof e === 'object' && (e.detail || e.title) || e).join(', ');
      throw new ErrorStatus(response.status, response.statusText, errors);
    });
  }

  return formatter;
}

export const parseDatetime = (value, custom = []) => {
  const format = [
    ...custom,
    'YYYY-MM-DDTHH:mm:ss',
    'YYYY-MM-DDTHH:mm:ssZ',
    'YYYY-MM-DDTHH:mm:ss.SSS',
    'YYYY-MM-DDTHH:mm:ss.SSSZ',
    'YYYY-MM-DDTHH:mm:ss.SSSSSSZ',
  ];

  if (typeof value === 'string' && moment(value, format, true).isValid()) {
    return moment.tz(value, window.TIME_ZONE);
  }

  return value;
};

const parseDatetimeItem = obj => 
  !isString(obj)
    ? Object.keys(obj).reduce((cur, key) => {
      let value = obj[key];

      if (typeof value === 'object' && value !== null) {
        value = parseIt(parseDatetimeItem, value);
      } else {
        value = parseDatetime(value);
      }

      return {
        ...cur,
        [key]: value,
      };
    }, {})
  : obj;

export function parseDatetimeValues(response) {
  return new Promise((resolve) => {
    resolve(parseIt(parseDatetimeItem, response));
  });
}


export const parseProps = el => Object.values(el.attributes)
  .reduce((cur, { nodeName, nodeValue }) => {
    if (nodeName !== 'root-react') {
      return {
        ...cur,
        // eslint-disable-next-line no-use-before-define
        [kebabCaseToLowerCamelCase(nodeName)]: applyToValues(parseDatetime, applyToKeys(snakeCaseToLowerCamelCase, parseJSON(nodeValue))),
      };
    }
    return cur;
  }, {});

export const applyToObj = fn => parse => obj => (
  typeof obj === 'object' && obj !== null
    ? Object.keys(obj).reduce((cur, pre) => ({
      ...cur,
      ...fn(obj, pre, parse),
    }), {})
    : obj
);

export const applyToObjKeys = applyToObj((obj, pre, parse) => ({
  // eslint-disable-next-line no-use-before-define
  [parse(pre)]: applyToKeys(parse, obj[pre]),
}));

export const applyToObjValues = applyToObj((obj, pre, parse) => ({
  // eslint-disable-next-line no-use-before-define
  [pre]: parse(obj[pre]),
}));


export const applyToValueAndKeys = applyToObj((obj, pre, parse) => parse(pre, obj[pre]));

export const applyToKeys = (fn, data) => {
  const apply = applyToObjKeys(fn);

  if (isArray(data)) {
    return data.map(apply);
  }
  if (typeof data === 'object' && data !== null) {
    return apply(data);
  }

  return data;
};

/**
 * Apply a function in every value
 *
 * @param {function} fn
 * @param {array|object} data
 * @returns {object}
 */
export const applyToValues = curry((fn, data) => {
  const apply = applyToObjValues(fn);
  if (isArray(data)) {
    return data.map(apply);
  }
  if (typeof data === 'object' && data !== null) {
    return apply(data);
  }

  return fn(data);
});

export const applyToBothDeep = (fn, data) => {
  const apply = applyToValueAndKeys(fn);
  if (isArray(data)) {
    return data.map(apply);
  }
  if (typeof data === 'object' && data !== null) {
    return apply(data);
  }

  return data;
};

export const momentToString = value => (
  value instanceof moment ? value.format('YYYY-MM-DD HH:mm:ss') : value
);

export const momentToTimezoneServer = value => (
  value instanceof moment ? moment.tz(value, window.TIME_ZONE) : value
);

export const parseResponseObject = object => applyToKeys(snakeCaseToLowerCamelCase, object);

export const prepareBody = body => (
  applyToKeys(camelCaseToLowerSnakeCase, applyToValues(momentToString, body))
);

export const parseBody = body => (
  JSON.stringify(prepareBody(body))
);

export const parseQuery = query => (
  qs.stringify(applyToKeys(camelCaseToLowerSnakeCase, query))
);

/**
 * This will filter the query to return only keys include on fields list
 *
 * @param {array} [fields=[string]]
 * @param {object} query
 * @returns {object}
 */

export const filterParamsCurried = curry((fields, query) => fields
  .reduce((acc, cur) => (
    typeof query[cur] !== 'undefined' ? ({ ...acc, [cur]: query[cur] }) : acc
  ), {}));

export const filterParams = (query, field) => filterParamsCurried(field, query);


/**
 * It will check all values and filter using function external
 *
 * @param {function} fn
 * @param {object} query
 * @returns {object}
 */
export const filterValues = curry((fn, query) => Object.keys(query)
  .reduce((acc, cur) => (
    fn(query[cur]) ? ({ ...acc, [cur]: query[cur] }) : acc
  ), {}));


export const dataToFlat = ({ attributes, ...otherProps }) => ({
  ...otherProps,
  ...attributes,
});

export const parseDataToFlat = data => data
  .map(item => (has(item, 'data') ? item.data : item))
  .map(dataToFlat);

export const flatResponse = response => ({
  ...response,
  data: parseDataToFlat(response.data),
});

export const parseDataToSelectOptions = (options, data) => data.map(item => ({
  ...item,
  label: item[options.labelKey],
  value: item[options.valueKey],
  key: (item[options.valueKey] + item[options.labelKey]),
}));

export const checkPeriod = (start, end, current) => {
  if (current.diff(end) > 0) {
    return 'after';
  }

  if (current.diff(start) < 0) {
    return 'before';
  }

  return 'between';
};

export function mergeNotesIntoHistory(notes, history) {
  const mergedHistory = [
    ...notes.map(note => ({ ...note, modifiedAt: note.addedAt, isNote: true })),
    ...history,
  ];

  mergedHistory.sort((a, b) => {
    const dateA = new Date(a.modifiedAt);
    const dateB = new Date(b.modifiedAt);

    if (dateA > dateB) {
      return -1;
    }

    if (dateA < dateB) {
      return 1;
    }

    return 0;
  });

  return mergedHistory;
}

export function mergeResourceStatus(first, second) {
  const isError = first.isError || second.isError;
  const isLoading = first.isLoading || second.isLoading;

  const error = first.error || second.error || {};
  const message = first.message || second.message || '';

  return {
    isError,
    isLoading,
    error,
    message,
  };
}

export const getPreviousChangedStatus = (history, rowIndex) => history.find((item, index) => {
  if (rowIndex < index && !item.isNote) {
    return true;
  }
  return false;
});

export const getBalanceStatusClasses = (balance, creditLimit, isBalanceLow, isPostpay) => ({
  'alert-success': isPostpay ? (creditLimit > balance) : (!isBalanceLow && balance >= 0),
  'alert-warning': !isPostpay && (isBalanceLow && balance >= 0),
  'alert-danger': isPostpay ? (creditLimit <= balance) : (balance < 0),
});

export function addLeadingZero(str) {
  if (str < 10) {
    return `0${str}`;
  }
  return str;
}

export const convertToString = curry((defaultString, value) => (
  defaultTo(value, defaultString)
));


export const convertToNA = convertToString('N/A');

/**
 * Convert some string to null
 *
 * @param {string} value
 * @return {string} empty string or same value
 */
export const convertSomeStringToNull = curry((defaultString, value) => (
  value !== defaultString ? value : null
));

export const convertDataValues = curry((options, data) => applyToBothDeep((key, value) => ({
  [key]: isFunction(options[key]) ? options[key](value) : value,
}), data));

const letters = {
  A: 2,
  B: 2,
  C: 2,
  D: 3,
  E: 3,
  F: 3,
  G: 4,
  H: 4,
  I: 4,
  J: 5,
  K: 5,
  L: 5,
  M: 6,
  N: 6,
  O: 6,
  P: 7,
  Q: 7,
  R: 7,
  S: 7,
  T: 8,
  U: 8,
  V: 8,
  W: 9,
  X: 9,
  Y: 9,
  Z: 9,
};

export const convertNumberVanity = (numberVanity) => {
  if (typeof numberVanity !== 'string') return numberVanity;

  const clearNumbers = numberVanity.toUpperCase().replace(/[^A-Z0-9]/i, '').split('');

  return clearNumbers.reduce((acc, cur) => {
    const translated = Number.isNaN(parseInt(cur, 10)) ? letters[cur] : cur;

    acc.push(translated);

    return acc;
  }, []).join('');
};

/**
 * parametersFormatter - convert object javascript to url query string parameter
 * and create a query string paramters format a=2&b=23&c=443
 * @params {object} - params should have all properties to make a query
 * @return {string} - a=2&b=23&c=443
 * */
export function parametersFormatter(params, sufix = '') {
  const validParams = Object.keys(params).reduce((acc, key) => {
    const param = params[key];
    if (param !== undefined && param !== '') acc[key] = param;

    return acc;
  }, {});
  return [
    qs.stringify(validParams),
    sufix,
  ]
    .filter(string => string.length)
    .join('&');
}

export const parseMultQueryParam = (name, query) => {
  if (typeof query[name] === 'undefined') return [query];

  const value = query[name];
  const withoutNamedKey = omit(query, [name]);
  const parsed = Array.isArray(value) ? value.map(it => `${name}=${it}`).join('&') : value;
  return [withoutNamedKey, parsed];
};

/**
 * if the value is a string
 *
 * @param {string} string
 * @returns {boolean|value}
 */
export const onlyNotEmptyString = value => (
  typeof value === 'string' ? value.length : value
);


/**
 * Parse data to query string and add it on url
 *
 * @param {string} url
 * @param {array} fields
 * @param {object} data
 * @returns {string}
 */
export const addQueryString = (url, fields, data = {}) => {
  if (!Object.keys(data).length) return url;

  const query = compose(
    parseQuery,
    applyToValues(momentToString),
    filterValues(onlyNotEmptyString),
    filterParamsCurried(fields),
  )(data);

  return `${url}?${query}`;
};

export const isPast = calendarDay => (
  calendarDay.diff(moment.tz('utc')) < 0
);

/**
 * Assure that a value is inside a given closed interval.
 *
 * @param {number} value - to be asssured inside the interval
 * @param {number} floorValue - used if the value is less
 * @param {number} ceilValue - used if the the value is greater
 * @return {number}
 */
export function between(value, floorValue, ceilValue) {
  const floord = max([value, floorValue]);
  const alsoCeiled = min([floord, ceilValue]);
  return alsoCeiled;
}

/**
 * It will limit number of elements on array
 * @param {number} limit
 * @param {array} data
 */
export const limitData = slice(0);


export const limitDataPaginated = (reducer) => {
  if (!has(reducer, 'response.meta.perPage')) return reducer;

  const { response: { meta: { perPage } } } = reducer;
  return {
    ...reducer,
    data: limitData(perPage, reducer.data),
  };
};

/**
 *
 * Util to map values to `Promise`s and invoke them in queue order,
 * being assured each `Promise` is resolved before the next be created.
 *
 * It reduces `data` array to a `Promise` which is the accumulated chain
 * of async tasks resulting of each element mapped thru `asyncIteratee`.
 * As a chain, each `asyncIteratee` follows a queue order of call, and
 * may optionally have the data chunked or delayed.
 *
 * @param {Array} data - array of data to be given to `asyncIteratee` calls
 * @param {Function} asyncIteratee - function mapping `data` chunks to `Promise`s
 * @param {Object} [options={}]
 * @param {number} [options.chunksSize=1] - size of chunks to split `data`
 * before passing thru iteratee
 * @param {number} [options.interval=0] - delay (in ms) before every
 * iteratee resolves
 * @return {Promise} resulting of all chaining
 */
export function applyToQueuedPromises(data, asyncIteratee, options = {}) {
  const { chunksSize = 1, interval = 0 } = options;

  return chunk(data, chunksSize).reduce((chainedPromises, ...mapArgs) => {
    const makeNextPromise = () => new Promise((resolve, reject) => {
      asyncIteratee(...mapArgs).then(() => setInterval(resolve, interval)).catch(reject);
    });

    return chainedPromises.then(makeNextPromise);
  }, Promise.resolve());
}

/**
 * Uses `applyToQueuedPromises`, but with a middleware to provide
 * the percentage of tasks runned thru `asyncIteratee`.
 *
 * @param {*} data - to be forwarded
 * @param {*} asyncIteratee - to be forwarded (with middleware applied)
 * @param  {...any} argsRest - rest of arguments to be forwarded.
 * @return {Promise} same as `applyToQueuedPromises`
 */
export function applyToQueuedPromisesWithPercentage(data, asyncIteratee, ...argsRest) {
  const iterateeAdapter = (chunkedPart, index, allChunks) => {
    const progressStep = index + 1;
    const totalSteps = allChunks.length;
    const progressPercentage = (progressStep * 100) / totalSteps;
    return asyncIteratee(chunkedPart, progressPercentage);
  };

  return applyToQueuedPromises(data, iterateeAdapter, ...argsRest);
}

/**
 * Coalescing version of `get` (lodash).
 *
 * @param {object} object - object to have data extracted
 * @param {string} path - key path for object extraction
 * @param {*} fallbackValue - to be used if the extraction is falsy
 * @return {*} value extracted or the `fallbackValue` if it extracted a falsy
 */
export function truthyGet(object, path, fallbackValue) {
  return get(object, path) || fallbackValue;
}
