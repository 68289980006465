import React from 'react'
import './style.scss';

export default function ErrorInfo() {
	return (
		<div className="error-info">
			<h1>Something went wrong</h1>
			<h3> Go back to the <a href="/">main page</a> </h3>
			<h3> If you see this page repeatedly, please contact <a href="mailto:support@flowroute.com" target="_blank" rel="noopener noreferrer">Support</a>.</h3>
	  </div>
	)
}
