import * as API from 'app/api';
export const BALANCE_SUCCEEDED = 'BALANCE_SUCCEEDED';
export const BALANCE_REQUESTED = 'BALANCE_REQUESTED';
export const BALANCE_FAILED = 'BALANCE_FAILED';


export function balanceSucceeded({ balance, warningThreshold, creditLimit, isPostpay, availableFunds }) {
  return {
    type: BALANCE_SUCCEEDED,
    balance,
    warningThreshold,
    creditLimit,
    isPostpay,
    availableFunds,
  };
}

export function balanceRequested(isLoading = true) {
  return {
    type: BALANCE_REQUESTED,
    isLoading
  }
}

export function balanceFailed(error) {
  return {
    type: BALANCE_FAILED,
    error
  }
}

export function fetchBalance(showLoadingBar = true, force = false) {
  return (dispatch, getState) => {
    const { techPrefix } = getState().customer;
    if (!window.balanceTimeoutId) {
      dispatch(balanceRequested(showLoadingBar));
    }

    let timeToFirstBalanceFetch = 0;
    try {
      if (force) {
        throw new Error('Skipping rateLimit synchronization as "force" flag is set to true');
      }
      const lastFetchTimestamp =
      Number((localStorage.getItem(`last-balance-fetch-timestamp-${techPrefix}`) || 0));
      if (isNaN(lastFetchTimestamp)) {
        throw new Error(`LocalStorage value for key 'last-balance-fetch-timestamp' is invalid`);
      }
      timeToFirstBalanceFetch = Math.max(60000 - (Date.now() - lastFetchTimestamp), 0);
      const cachedBalance = JSON.parse(localStorage.getItem(`customer-cached-balance-${techPrefix}`));
      if (timeToFirstBalanceFetch && cachedBalance) {
        dispatch(balanceSucceeded(cachedBalance));
      }
      if (window.balanceTimeoutId) {
        return Promise.resolve();
      }
    } catch(e) {
      timeToFirstBalanceFetch = 0;
      console.error(e.message);
    }

    return new Promise((resolve) => { window.balanceTimeoutId = setTimeout(resolve, timeToFirstBalanceFetch); })
      .then(API.balance)
      .then(
        ({ balance, warningThreshold, cl: creditLimit, isPostpay, availableFunds }) => ({
          balance,
          warningThreshold,
          creditLimit,
          isPostpay,
          availableFunds,
        }),
      )
      .then((body) => {
        window.balanceTimeoutId = null;
        localStorage.setItem(`last-balance-fetch-timestamp-${techPrefix}`, Date.now());
        localStorage.setItem(`customer-cached-balance-${techPrefix}`, JSON.stringify(body));
        dispatch(balanceSucceeded(body));
        return body;
      })
      .catch((error) => {
        window.balanceTimeoutId = null;
        dispatch(balanceFailed(error));
        return Promise.reject(error);
      });
  };
}

