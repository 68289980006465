import React from 'react';
import PropTypes from 'prop-types';
import Confirm from 'react-confirm-bootstrap';
import classNames from 'classnames';
import validator from 'validator'; // need use `npm install --save validator` before remove WIP tag
import Textarea from 'app/components/textarea';
import { If } from 'app/components/render-utils/conditional-rendering';

import './note.scss';

class Note extends React.Component {
  constructor(props) {
    super(props);
    this.updateNote = this.updateNote.bind(this);
    this.toggleEditMode = this.toggleEditMode.bind(this);
    this.confirmEdit = this.confirmEdit.bind(this);

    this.state = {
      isEditing: false,
      editedNote: props.note,
      isUpdated: false,
      isChanged: false,
      isEmpty: false,
    };
  }

  componentDidMount() {
    this.isFormEmpty();
  }

  componentDidUpdate(prevProps, prevState) {
    const { isEditing } = this.state;
    if (!prevState.isEditing && isEditing) {
      this.textArea.focus();
    }
  }


  toggleEditMode() {
    const { note } = this.props;

    this.setState(({ isEditing }) => ({
      isEditing: !isEditing,
      editedNote: note,
      isUpdated: false,
    }));
  }

  updateNote(e) {
    this.setState({
      editedNote: e.target.value,
    }, () => {
      this.isFormChanged();
      this.isFormEmpty();
    });
  }

  isFormChanged() {
    const { props, state } = this;
    const isChanged = props.note !== state.editedNote;

    this.setState({
      isChanged,
    });
  }

  isFormEmpty() {
    const { editedNote } = this.state;

    const isEmpty = validator.isEmpty(editedNote);

    this.setState({
      isEmpty,
    });
  }

  async confirmEdit() {
    const { noteId, updateNote } = this.props;
    const { editedNote, isChanged, isEmpty } = this.state;
    this.setState({
      isUpdated: false,
    });

    try {
      if (isChanged && !isEmpty) {
        await updateNote(noteId, editedNote);
      }
      this.isFormChanged();
      this.setState({
        isEditing: false,
        isUpdated: true,
      });
    } catch (e) {
      console.error(e);
    }
  }

  renderTextArea() {
    const { author, isLoading } = this.props;
    const { editedNote } = this.state;

    return (
      <div className="note__group">
        <strong className="note__author">{author}:</strong>
        <Textarea
          ref={textArea => this.textArea = textArea}
          className="note__textarea"
          disabled={isLoading}
          value={editedNote}
          onInput={this.updateNote}
          submit={this.confirmEdit}
          onBlur={this.toggleEditMode}
        />
      </div>
    );
  }

  renderNote() {
    const { author, note } = this.props;
    return (
      <React.Fragment>
        <pre><strong>{author}:</strong> {note}</pre>
      </React.Fragment>
    );
  }

  renderActions() {
    const { isLoading } = this.props;
    const { isEditing, isEmpty, isChanged } = this.state;
    const submitBtn = classNames(
      'fa', {
        'fa-check': !isLoading,
        'fa-spin fa-spinner': isLoading,
      },
    );

    return (
      <div className="note__actions">
        <If condition={!isEditing}>
          <button type="button" className="note__button-edit" onClick={this.toggleEditMode}>
            <i className="fa fa-edit" />
          </button>
        </If>
        <If condition={isEditing}>
          <If condition={isChanged}>
            <Confirm
              onConfirm={this.toggleEditMode}
              body="Are you sure you want cancel the Editing of this note?"
              confirmText="DISCARD"
              cancelText="KEEP EDITING"
              title="Cancel Editing?"
            >
              <button type="button" className="note__button-cancel" disabled={isLoading}>
                <i className="fa fa-times" />
              </button>
            </Confirm>
          </If>
          <If condition={!isChanged}>
            <button type="button" className="note__button-cancel" disabled={isLoading} onClick={this.toggleEditMode}>
              <i className="fa fa-times" />
            </button>
          </If>
          <button
            type="button"
            disabled={isLoading || isEmpty}
            className="note__button-confirm"
            onClick={this.confirmEdit}
          >
            <i className={submitBtn} />
          </button>
        </If>
      </div>
    );
  }

  render() {
    const { isEditable } = this.props;
    const { isEditing, isUpdated } = this.state;

    return (
      <div
        className={classNames('note', {
          'note--editing': isEditing,
          'note--updated': isUpdated,
        })}
      >
        <div className="note__content">
          <If condition={isEditing}>
            {this.renderTextArea()}
          </If>
          <If condition={!isEditing}>
            {this.renderNote()}
          </If>
        </div>

        <If condition={isEditable}>
          {this.renderActions()}
        </If>
      </div>
    );
  }
}

Note.propTypes = {
  noteId: PropTypes.number.isRequired,
  isEditable: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  note: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  updateNote: PropTypes.func.isRequired,
};

Note.defaultProps = {
  isLoading: false,
};

export default Note;
