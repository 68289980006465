import { parseDatetimeValues, prepareBody, addQueryString } from 'app/utils';
import { api } from './index';

function getAccountCNAMs({ credentials, ...query }) {
  return api(addQueryString('/v2/cnams', ['offset', 'limit', 'isApproved', 'value'], query), {
    method: 'GET',
    credentials,
    getMeta: true,
  }).then(parseDatetimeValues);
}

function postAccountCNAM({ credentials, data: { value } }) {
  return api('/v2/cnams', {
    method: 'POST',
    credentials,
    getMeta: true,
    body: prepareBody({ value }),
  });
}

function patchAccountCNAM({ credentials, id, data }) {
  return api(`/v2/cnams/${id}`, {
    method: 'PATCH',
    credentials,
    getMeta: true,
    body: prepareBody(data),
  });
}

function deleteAccountCNAM({ credentials, id }) {
  return api(`/v2/cnams/${id}`, {
    method: 'DELETE',
    credentials,
    getMeta: true,
  });
}

export default {
  get: getAccountCNAMs,
  post: postAccountCNAM,
  patch: patchAccountCNAM,
  delete: deleteAccountCNAM,
};
