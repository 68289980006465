import * as API from 'app/api';

export const CUSTOMER_SUCCEEDED = 'CUSTOMER_SUCCEEDED';
export const CUSTOMER_REQUESTED = 'CUSTOMER_REQUESTED';
export const CUSTOMER_FAILED = 'CUSTOMER_FAILED';
export const SET_POP_SUCCEEDED = 'SET_POP_SUCCEEDED';
export const SET_POP_INPUT = 'SET_POP_INPUT';
export const SET_POP_REQUESTED = 'SET_POP_REQUESTED';
export const SET_POP_FAILED = 'SET_POP_FAILED';
export const RESET_SIP_PASSWORD_SUCCEEDED = 'RESET_SIP_PASSWORD_SUCCEEDED';
export const RESET_SIP_PASSWORD_REQUESTED = 'RESET_SIP_PASSWORD_REQUESTED';
export const RESET_SIP_PASSWORD_FAILED = 'RESET_SIP_PASSWORD_FAILED';
export const CLEAR_RESPONSE = 'CLEAR_RESPONSE';

export function customerSucceeded(customer) {
  return {
    type: CUSTOMER_SUCCEEDED,
    payload: customer,
  };
}

export function customerRequested() {
  return {
    type: CUSTOMER_REQUESTED,
  };
}

export function customerFailed(error) {
  return {
    type: CUSTOMER_FAILED,
    error: { ...error, isError: true },
  };
}

export function fetchCustomer() {
  return (dispatch) => {
    dispatch(customerRequested());

    return API.customer()
      .then(body => dispatch(customerSucceeded(body)))
      .catch(error => dispatch(customerFailed(error)));
  };
}

export function setPopInput(name, value) {
  return {
    type: SET_POP_INPUT,
    payload: {
      formData: {
        [name]: value,
      },
    },
  };
}

export function setPopRequested() {
  return {
    type: SET_POP_REQUESTED,
  };
}

export function setPopSucceeded(response) {
  return {
    type: SET_POP_SUCCEEDED,
    payload: {
      setPopResponse: { ...response, isError: false },
    },
  };
}

export function setPopFailed(error) {
  return {
    type: SET_POP_FAILED,
    payload: {
      setPopResponse: { ...error, isError: true },
    },
  };
}

export function setPop(params) {
  return (dispatch) => {
    dispatch(setPopRequested());

    return API.setPop(params)
      .then(response => dispatch(setPopSucceeded(response)))
      .catch(error => dispatch(setPopFailed(error)));
  };
}

export function resetSipPasswordRequested() {
  return {
    type: RESET_SIP_PASSWORD_REQUESTED,
  };
}

export function resetSipPasswordSucceeded(response) {
  return {
    type: RESET_SIP_PASSWORD_SUCCEEDED,
    payload: {
      resetSipPasswordResponse: { ...response, isError: false },
    },
  };
}

export function resetSipPasswordFailed(error) {
  return {
    type: RESET_SIP_PASSWORD_FAILED,
    payload: {
      resetSipPasswordResponse: { ...error, isError: true },
    },
  };
}

export function resetSipPassword() {
  return (dispatch) => {
    dispatch(resetSipPasswordRequested());

    return API.resetSipPassword()
      .then(response => dispatch(resetSipPasswordSucceeded(response)))
      .catch(error => dispatch(resetSipPasswordFailed(error)));
  };
}

export function clearResponse(responseKey) {
  return {
    type: CLEAR_RESPONSE,
    payload: {
      responseKey,
    },
  };
}
